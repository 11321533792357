// Customizable Area Start

import React from "react";
import { Message } from "../../../../framework/src/Message";
import { IBlock } from "../../../../framework/src/IBlock";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";

import ISuccessResponse from "../ISuccessResponse";
import GetApiMessage from "../GetApiMessage";
import ApiResponseMiddleware from "../ApiResponseMiddleware";

const configJs = require("./config");
const ApiUrls = configJs.ApiUrls;

interface Props {
  navigation: {
    navigate: Function;
  };
}
interface SS {}
interface S {
  selectedProductIds: Array<string>;

  // Lists
  categoryList: Array<any>;
  inventoryList: Array<any>;
  categoryWiseList: Array<any>;
  searchCategoryList: Array<any>;

  // Logical
  currentPage: number;
  lastPage: number;
  isApproved: any;
  querySearch: string | any;
  selectedCategoryId: Array<any>;
  removeItemFlag: any;
  selectAllItemFlag: boolean;
  isRefundable: boolean;
  isStocakble: boolean;

  // API IDs
  downloadInventoryMessageId: string;
  editCatalogsMessageId: any;
  showProfileMessageId: any;
  deleteProductApiId: any;
  categoryWiseListId: any;
  categoryListMessageId: any;
  searchCategoryListMessageId: any;
  inventoryListMessageId: any;
}

class InventoryController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedProductIds: [],

      // Lists
      categoryList: [],
      inventoryList: [],
      categoryWiseList: [],
      searchCategoryList: [],

      // Logical
      currentPage: 1,
      lastPage: 1,
      isApproved: false,
      querySearch: null,
      selectedCategoryId: [],
      removeItemFlag: null,
      selectAllItemFlag: false,
      isRefundable: false,
      isStocakble: false,

      // API IDs
      editCatalogsMessageId: null,
      showProfileMessageId: null,
      deleteProductApiId: null,
      categoryWiseListId: null,
      categoryListMessageId: null,
      searchCategoryListMessageId: null,
      inventoryListMessageId: null,
      downloadInventoryMessageId: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
    ]);

    this.receive = this.receive.bind(this);
  }

  async receive(from: string, message: Message) {
    if (!ApiResponseMiddleware(message)) return;

    const {
      downloadInventoryMessageId,
      editCatalogsMessageId,
      showProfileMessageId,
      selectedProductIds,
      deleteProductApiId,
      categoryWiseListId,
      inventoryListMessageId,
      categoryListMessageId,
      searchCategoryListMessageId,
    } = this.state;

    let requestID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (categoryListMessageId === requestID) {
      if (successResponse) {
        this.setState({ categoryList: successResponse.data ?? [] });
      }
    } else if (searchCategoryListMessageId === requestID) {
      if (successResponse) {
        this.setState({
          lastPage: successResponse?.meta?.last_page ?? 1,
        });
        let data = successResponse.data || [];
        data = data.map((item: any) => {
          return { id: item.id, ...item.attributes, selected: false };
        });

        this.setState({ searchCategoryList: data });

        if (this.state.selectAllItemFlag) this.onSelectAllChange();
      }
    } else if (inventoryListMessageId === requestID) {
      if (successResponse) {
        this.setState({
          lastPage: successResponse?.meta?.last_page ?? 1,
        });
        let data = successResponse.data || [];
        data = data.map((item: any) => {
          return { id: item.id, ...item.attributes, selected: false };
        });
        this.setState({ inventoryList: data });
      }
    } else if (categoryWiseListId === requestID) {
      if (successResponse) {
        this.setState({
          lastPage: successResponse?.meta?.last_page ?? 1,
        });
        let data = successResponse.data || [];
        data = data.map((item: any) => {
          return { id: item.id, ...item.attributes, selected: false };
        });
        this.setState({ categoryWiseList: data });
      }
    } else if (deleteProductApiId === requestID) {
      if (successResponse) {
        this.setState({ removeItemFlag: "deleted" });
        // refetch all lists
        this.getInventoryDataApi();
        this.getDataBySearchQueryApi();
        this.getCategoryDataFromApi(selectedProductIds);
      }
    } else if (showProfileMessageId === requestID) {
      if (successResponse) {
        let isApproved =
          successResponse?.data?.attributes?.status === "approved";
        this.setState({ isApproved });
      }
    } else if (editCatalogsMessageId === requestID) {
      if (successResponse) {
        this.getInventoryDataApi();
        this.getDataBySearchQueryApi();
        this.getCategoryDataFromApi(selectedProductIds);
      }
    } else if (downloadInventoryMessageId === requestID) {
      if (successResponse) {
        let link = successResponse?.url;
        window.open(link);
      }
    }
  }

  componentDidMount(): any {
    this.getCategoriesFromApi();
    this.getInventoryDataApi();
    this.getUserData();
  }

  getUserData() {
    const requestMessage = GetApiMessage({
      method: "get",
      endpoint: ApiUrls.viewProfile,
    });

    this.setState({ showProfileMessageId: requestMessage.messageId });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onSelectAllChange() {
    const {
      selectAllItemFlag,
      selectedCategoryId,
      querySearch,
      categoryWiseList,
      searchCategoryList,
      inventoryList,
    } = this.state;

    let list = [];
    // @ts-ignore
    if (selectedCategoryId !== -1 && selectedCategoryId?.length > 0)
      list = categoryWiseList;
    else if (querySearch) list = searchCategoryList;
    else list = inventoryList;

    if (selectAllItemFlag) {
      this.setState({ selectedProductIds: [] });
      this.setState({ selectAllItemFlag: false });
    } else {
      let ids = list.map(({ id }) => id);
      this.setState({ selectedProductIds: ids });
      this.setState({ selectAllItemFlag: true });
    }
  }

  onChangeProductSelection(id: any) {
    let ids: Array<string> = this.state.selectedProductIds;
    let idIndex = ids.indexOf(id);
    idIndex === -1 ? ids.push(id) : ids.splice(idIndex, 1);

    this.setState({ selectedProductIds: ids });
  }

  getCategoriesFromApi() {
    let requestMessage = GetApiMessage({
      method: "get",
      endpoint: ApiUrls.categoryList,
    });
    this.setState({ categoryListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDataBySearchQueryApi(currentPage: number = this.state.currentPage) {
    const { isRefundable, isStocakble } = this.state;
    let extraString = `refundable=${isRefundable}&stockable=${isStocakble}`;
    let query: any = encodeURI(this.state.querySearch || "");
    let requestMessage = GetApiMessage({
      method: "get",
      endpoint:
        ApiUrls.searchCatalogue +
        `?key=${encodeURI(
          query
        )}&${extraString}&per_page=10&page=${currentPage}`,
    });
    this.setState({ searchCategoryListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInventoryDataApi(currentPage: number = this.state.currentPage) {
    let requestMessage = GetApiMessage({
      method: "get",
      endpoint: ApiUrls.listInventory + "?per_page=10&page=" + currentPage,
    });
    this.setState({ inventoryListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getLastPageByCondition() {
    const { selectedCategoryId, querySearch, isStocakble, isRefundable } =
      this.state;
    if (selectedCategoryId.length !== 0) return "category";
    let querySearchFlag = querySearch || isRefundable || isStocakble;
    return querySearchFlag ? "search" : "inventory";
  }

  getInventoryListByCondition() {
    const {
      querySearch,
      inventoryList,
      categoryWiseList,
      selectedCategoryId,
      searchCategoryList,
      isRefundable,
      isStocakble,
    } = this.state;

    if (selectedCategoryId.length !== 0) return categoryWiseList;

    let querySearchFlag = querySearch || isRefundable || isStocakble;

    return querySearchFlag ? searchCategoryList : inventoryList;
  }

  getCategoryDataFromApi(
    ids: any,
    currentPage: number = this.state.currentPage
  ) {
    let requestMessage = GetApiMessage({
      method: "get",
      endpoint:
        ApiUrls.categoryWiseList +
        `?ids=[${ids.join(",")}]&per_page=10&page=${currentPage}`,
    });
    this.setState({
      categoryWiseListId: requestMessage.messageId,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onSelectCategoryChange(value: any) {
    this.setState({ selectedCategoryId: value, currentPage: 1 });

    if (value.length !== 0) this.getCategoryDataFromApi(value);
    else {
      this.getInventoryDataApi(1);
      this.setState({ categoryWiseList: [] });
    }

    this.setState({ selectAllItemFlag: false });
    this.setState({ selectedProductIds: [] });
  }

  onDeleteSelectedItems() {
    let requestMessage = GetApiMessage({
      method: "delete",
      endpoint:
        ApiUrls.deleteCatalogue +
        `?ids=[${this.state.selectedProductIds.join(",")}]`,
    });
    this.setState({ deleteProductApiId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSelectRenderValue(values: any) {
    if (values.length === 0) return <>Category</>;

    let result = this.state.categoryList
      .map(({ attributes }) => {
        const { name, id } = attributes;
        return values.includes(id) ? name : null;
      })
      .filter((item) => item !== null);

    return result.join(", ");
  }

  onChangeQueryInput(value: string) {
    this.setState({ currentPage: 1, lastPage: 1 });

    if (!value) {
      this.setState({ searchCategoryList: [] });
      this.getInventoryDataApi(1);
    } else this.setState({ selectedCategoryId: [] });
    this.setState({ querySearch: value });
    this.setState({ selectAllItemFlag: false });
    this.setState({ selectedProductIds: [] });
  }

  onUpdateActiveStatus(item: any) {
    let form = new FormData();
    form.set("status", item.status === "active" ? "not active" : "active");
    let requestMessage = GetApiMessage({
      data: form,
      method: "put",
      endpoint: ApiUrls.editCatalogue + item.id,
    });
    this.setState({ editCatalogsMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onChangePage(page: number) {
    let listType = this.getLastPageByCondition();

    if (listType === "inventory") this.getInventoryDataApi(page);
    if (listType === "search") this.getDataBySearchQueryApi(page);
    else this.getCategoryDataFromApi(this.state.selectedCategoryId, page);

    this.setState({ currentPage: page });
  }

  onDownloadInventory() {
    let requestMessage = GetApiMessage({ endpoint: ApiUrls.downloadInventory });
    this.setState({ downloadInventoryMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

export default InventoryController;

// Customizable Area End
