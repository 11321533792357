// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../../framework/src/Message";

import GetApiMessage from "../GetApiMessage";
import ISuccessResponse from "../ISuccessResponse";
import ApiResponseMiddleware from "../ApiResponseMiddleware";
import { toast } from "react-toastify";

const configJs = require("./config");
const ApiUrls = configJs.ApiUrls;

export interface ISubscriptionListRow {
  id: number;
  price: String;
  valid_from: String;
  description: String;
  valid_up_to: String;
  account_type: String;
  expired: boolean;
  catalogues: any;
  subscribed: boolean;
  isSelected: boolean;
}

interface Props {
  navigation: {
    navigate: Function;
  };
}
interface State {
  // Pagination
  currentPage: number;
  lastPage: number;

  subscriptionList: Array<ISubscriptionListRow>;
  subscriptionListMessageId: string;
  selectedSubscription:number;
}
interface SS {}

class SubscriptionController extends BlockComponent<Props, State, SS> {
  state = {
    lastPage: 1,
    currentPage: 1,

    subscriptionList: [],
    subscriptionListMessageId: "",
    selectedSubscription:0
  };

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  receive(from: string, message: Message): void {
    if (!ApiResponseMiddleware(message)) return;

    const { subscriptionListMessageId } = this.state;

    let requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (successResponse) {
      if (requestId === subscriptionListMessageId) {
        if (successResponse.data) {
          this.setState({
            lastPage: successResponse?.meta?.last_page || 1,
            subscriptionList: successResponse?.data.map((item: any) => {
              return {
                id: item.id,
                isSelected: false,
                ...item.attributes,
              };
            }),
          });
        }
      }
    }
  }

  componentDidMount(): any {
    this.getSubscriptionList();
  }

  getSubscriptionList(page: number = this.state.currentPage) {
    let requestMessage = GetApiMessage({
      endpoint: ApiUrls.listSubscriptions + "?page=" + page + "&per_page=10",
    });
    this.setState({ subscriptionListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSelection=(e:any,id:number)=>{
    const selectedId=e.target.checked?id:0;
    this.setState({
      selectedSubscription:selectedId
    })
  }
  navigateToEditSubscription=()=>{
     const {selectedSubscription}=this.state;
     if(selectedSubscription!==0){
       this.props.navigation.navigate("VendorSub", {
        page: "edit-subscription-plan",
        id: selectedSubscription,
      })
     }else{
      toast.warn("Please select subscription to edit")
     }
  }

  onChangePage(page: number) {
    this.setState({ currentPage: page });
    this.getSubscriptionList(page);
  }
}

export default SubscriptionController;

// Customizable Area End
