// Customizable Area Start

import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";

import GetApiMessage from "../GetApiMessage";
import ISuccessResponse from "../ISuccessResponse";
import ApiResponseMiddleware from "../ApiResponseMiddleware";
import BlockHelpers from "../../../utilities/src/BlockHelpers";

const configJs = require("./config");
const ApiUrls = configJs.ApiUrls;
const Strings = configJs.Strings;

interface Props {}
interface SS {}
interface S {
  confirmDelete: boolean;
  isLoading: any;
  profileData: {
    dob: any;
    city: any;
    email: any;
    phone: any;
    state: any;
    pinCode: any;
    address: any;
    password: any;
    userName: any;
    lastName: any;
    firstName: any;
    isApproved: string;
  };
  uploadImageMessageId: any;
  apiCallId: any;
  profileEdit: any;
  profileImage: any;
  removeApiCallId: any;
  deactivateMessageId: any;
}

class ProfileSettingsController extends BlockComponent<Props, S, SS> {
  state = {
    confirmDelete: false,
    profileImage: "",
    isLoading: false,
    profileData: {
      isApproved: "",
      dob: "",
      city: "",
      email: "",
      phone: "",
      state: "",
      pinCode: "",
      address: "",
      password: "",
      userName: "",
      firstName: "",
      lastName: "",
    },
    profileEdit: false,
    uploadImageMessageId: null,
    apiCallId: null,
    removeApiCallId: null,
    deactivateMessageId: null,
  };

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (!ApiResponseMiddleware(message)) return;

    const {
      apiCallId,
      removeApiCallId,
      deactivateMessageId,
      uploadImageMessageId,
    } = this.state;

    let requestID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!successResponse) return;
    if (removeApiCallId === requestID) window.location.reload();
    if ([apiCallId, uploadImageMessageId].includes(requestID)) {
      const {
        profile_photo,
        dob,
        password,
        user_name,
        first_name,
        last_name,
        email,
        status,
        business_pin_code,
        full_phone_number,
        business_state,
        business_address,
        business_city,
      } = successResponse?.data?.attributes;

      let name = user_name;
      if (!name) name = first_name + " " + last_name;

      let profileData = {
        dob,
        email,
        isApproved: status,
        lastName: last_name,
        firstName: first_name,
        userName: name,
        password: password,
        city: business_city,
        state: business_state,
        phone: full_phone_number,
        pinCode: business_pin_code,
        address: business_address,
      };

      this.setState({
        profileData,
        profileImage: profile_photo,
      });

      this.setState({ isLoading: false });

      if (uploadImageMessageId === requestID) {
        window.location.reload();
      }
    }
    if (deactivateMessageId === requestID) {
      window.localStorage.clear();
      BlockHelpers.showAlert(
        Strings.labels.alert,
        Strings.message.deactivateAccountText
      );
      setTimeout(() => (window.location.href = "/"), 3000);
    }
  }

  componentDidMount(): any {
    this.getUserData();
  }

  getUserData() {
    this.setState({ isLoading: true });

    const requestMessage = GetApiMessage({
      method: "get",
      endpoint: ApiUrls.viewProfile,
    });

    this.setState({ apiCallId: requestMessage.messageId });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  editImage() {
    this.setState({ profileEdit: !this.state.profileEdit });
  }

  onUploadImage(files: any) {
    this.setState({ profileImage: files[0] ?? null, profileEdit: false });
    this.onUploadProfileApi(files[0] ?? null);
  }

  onUploadProfileApi(imageFile: any) {
    if (!imageFile) return;

    const form = new FormData();
    form.set("profile_photo", imageFile);
    const requestMessage = GetApiMessage({
      method: "patch",
      endpoint: ApiUrls.editProfile,
      data: form,
    });
    this.setState({ uploadImageMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onRemoveProfileImageApi() {
    const requestMessage = GetApiMessage({
      method: "delete",
      endpoint: ApiUrls.removePhoto,
    });
    this.setState({ removeApiCallId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.editImage();
  }

  onDeactivateAccount() {
    let form = new FormData();
    // @ts-ignore
    form.set("is_deactivated", true);
    const requestMessage = GetApiMessage({
      data: form,
      method: "PATCH",
      endpoint: ApiUrls.editProfile,
    });
    this.setState({ deactivateMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ confirmDelete: false });
  }
}

export default ProfileSettingsController;

// Customizable Area End
