// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import GetApiMessage  from "../../utilities/src/GetApiMessage";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  
}

interface S {
  loading: boolean;
  forgotInputValues: any;
  forgotValidation: any;
  forgotMessage: any;
  forgotPasswordMessageAPI: any;
  checkOTPMessageAPI: any;
  changePasswordMessageAPI: any;
  OTPInputValues: any;
  otpValidation: any;
  otpEmail: any;
  verifyOtpError: any;
  tabs: any;
  changePasswordError: any;
  passwordShown: boolean;
  changePasswordValidation : any;
  confirmPasswordShown: boolean;
  changePassword: any;
  otpToken: string;
}

interface SS {
  navigation: any;
}


export default class ForgotPasswordWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      loading: false,
      forgotInputValues: {
        email: ''
      },
      forgotValidation: {
        email: ''
      },
      forgotMessage: '',
      forgotPasswordMessageAPI: '',
      checkOTPMessageAPI: '',
      changePasswordMessageAPI: '',
      OTPInputValues: { otp: "" },
      otpValidation: { otp: "" },
      otpEmail: '',
      verifyOtpError: '',
      tabs: {
        first: true,
        second: false,
        third: false
      },
      changePasswordError: '',
      passwordShown: false,
      changePasswordValidation: {
        password: '',
        confirmPassword: ''
      },
      confirmPasswordShown: false,
      changePassword: {
        password: '',
        confirmPassword: ''
      },
      otpToken: ''
    };
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  async receive(from: string, message: Message) {
     if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.state.forgotPasswordMessageAPI !== null &&
      this.state.forgotPasswordMessageAPI ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson !== undefined && responseJson.data && responseJson.meta){
        this.setState({tabs: {first: false, second: true, third: false}, otpToken:  responseJson.meta.token, forgotMessage: ''});
      } else if (responseJson !== undefined && responseJson.errors) {
        this.setState({forgotMessage: responseJson.errors[0]?.otp});
      } else {
        this.setState({forgotMessage: 'Something went wrong.'});
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.state.checkOTPMessageAPI !== null &&
      this.state.checkOTPMessageAPI ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson !== undefined && responseJson.messages){
        this.setState({tabs: {first: false, second: false, third: true, verifyOtpError: ''}})
      } else if (responseJson !== undefined && responseJson.errors) {
        this.setState({verifyOtpError: responseJson.errors[0].otp});
      } else {
        this.setState({verifyOtpError: 'Something went wrong.'});
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.state.changePasswordMessageAPI !== null &&
      this.state.changePasswordMessageAPI ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson !== undefined && responseJson.length){
        window.location.href = '/';
      } else if (responseJson !== undefined && responseJson.errors) {
        this.setState({changePasswordError: responseJson.errors[0].profile});
      } else {
        this.setState({changePasswordError: 'Something went wrong.'});
      }
    }
  }  
  handleChange(e: any) {
    this.setState({
      forgotInputValues: {
        ...this.state.forgotInputValues,
        [e.target.name]: e.target.value
      }
    });
  }

  forgotPasswordValidation() {
    const formData = this.state.forgotInputValues;
    const errors = this.state.forgotValidation;
    // email validation
    const emailCond = configJSON.emailValidation;
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!formData.email.match(emailCond)) {
      errors.email = "Please enter valid email address";
    } else {
      errors.email = "";
    }
    this.setState({
      forgotValidation: { ...this.state.forgotValidation, errors }
    });
    if (errors.email === "") {
      return true;
    } else {
      return false;
    }
  }

  checkForgotEmail(){
    const checkValidation = this.forgotPasswordValidation();
    if(checkValidation === true){
      this.forgotEmail();
    }else{
      return false;
    }
  }

  async forgotEmail() {
    const data = {
      "data": {
          "attributes": {
              "email": this.state.forgotInputValues.email
          }
      }
  }
    const headers = {
      "Content-Type" : "application/json"
    }
    const forgotData = JSON.stringify(data);
    const requestMessage = GetApiMessage({ method: 'post', endpoint: configJSON.forgotPasswordAPIUrl, data: forgotData, extraHeaders: headers});
    this.setState({ forgotPasswordMessageAPI: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  OtpValidation() {
    const formData = this.state.OTPInputValues;
    const errors = this.state.otpValidation;
    const numberCond = /^[0-9\b]+$/;
    if (!formData.otp) {
      errors.otp = "OTP is required";
    } else if (!String(formData.otp).match(numberCond)) {
      errors.otp = "Please enter valid otp";
    } else {
      errors.otp = "";
    }
    if (errors.otp === "") {
      this.verifyForgotOtp(formData.otp);
    } else {
      this.setState({
        otpValidation: { ...this.state.otpValidation, errors }
      });
      return false;
    }
  }

  handleOTPChange(e: any) {
    this.setState({
      OTPInputValues: {
        ...this.state.OTPInputValues,
        [e.target.name]: e.target.value
      }
    });
  }
  verifyForgotOtp(otp: number){
    const data = {
      "data": {
          token: this.state.otpToken,
          "otp_code": otp
      }
  }
    const headers = {
      "Content-Type" : "application/json"
    }
    const forgotData = JSON.stringify(data);
    const requestMessage = GetApiMessage({ method: 'post', endpoint: configJSON.checkOtpAPIUrl, data: forgotData, extraHeaders: headers});
    this.setState({ checkOTPMessageAPI: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  togglePassword = () => {
    this.setState({
      passwordShown: !this.state.passwordShown
    });
  };
  toggleConfirmPassword = () => {
    this.setState({
      confirmPasswordShown: !this.state.confirmPasswordShown
    });
  };
  handleChangePasswordChange = (e: any) => {
    this.setState({
      changePassword: {
        ...this.state.changePassword,
        [e.target.name]: e.target.value
      }
    });
  }
  changePasswordValidation(){
    const formData = this.state.changePassword;
    const errors = this.state.changePasswordValidation;
    const password = formData.password;
    const passReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#.\$%\^&\*])(?=.{6})/;
    if (!password) {
      errors.password = "Password is required";
    } else if (!password.match(passReg)) {
      errors.password =
        "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
    } else {
      errors.password = "";
    }

    const confirmPassword = formData.confirmPassword;
    if (!confirmPassword) {
      errors.confirmPassword = "Confirm Password is required";
    } else if (!confirmPassword.match(passReg)) {
      errors.confirmPassword =
        "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
    } else if (password !== confirmPassword) {
      errors.confirmPassword = "Both password should match";
    } else {
      errors.confirmPassword = "";
    }

    this.setState({
      changePasswordValidation: { ...this.state.changePasswordValidation, errors }
    });
    if (
      errors.password === "" &&
      errors.confirmPassword === ""
      ) {
      return true;
    } else {
      return false;
    }
  }
  changePassword() {
    const checkValidation = this.changePasswordValidation();
    if(!checkValidation){
      return false;
    }else{
      const data = {
        data: {
                token: this.state.otpToken,
                new_password: this.state.changePassword.password,
                password_confirmation: this.state.changePassword.confirmPassword
        }
      }
      const headers = {
        "Content-Type" : "application/json"
      }
      const forgotData = JSON.stringify(data);
      const requestMessage = GetApiMessage({ method: 'post', endpoint: configJSON.changePasswordAPIUrl, data: forgotData, extraHeaders: headers});
      this.setState({ changePasswordMessageAPI: requestMessage.messageId });
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
}
// Customizable Area End