import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import GetApiMessage from "../../utilities/src/GetApiMessage";
import { string } from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  reduxState: { actions: { cart: { REFETCH: string } } }
  reduxDispatch: Function
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  productDetailMessageId: string;
  productDetail: any;
  quantity: any;
  addToCartMessageId: string;
  addToCartMessage: any;
  addToCartSuccessMessage: any;
  isRedirect: boolean;
  featuredMessageId: any;
  customerViewedMessageId: any;
  productRelatedMessageId: any;
  buyItAgainMessageId: any;
  frequentlyRepurchasedMessageId: any;
  featured: any;
  customerViewed: any;
  productRelated: any;
  buyItAgain: any;
  frequentlyRepurchased: any;
  imageUrl: any;
  cartTotal: any;
  placeOrder: any;
  isApproved: any;
  isOwnProduct: boolean;
  defaultAddress: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      productDetailMessageId: '',
      productDetail: [],
      quantity: 1,
      addToCartMessageId: '',
      addToCartMessage: '',
      addToCartSuccessMessage: '',
      isRedirect: false,
      featuredMessageId: '',
      customerViewedMessageId: '',
      productRelatedMessageId: '',
      buyItAgainMessageId: '',
      frequentlyRepurchasedMessageId: '',
      featured: [],
      customerViewed: [],
      productRelated: [],
      buyItAgain: [],
      frequentlyRepurchased: [],
      imageUrl: '',
      cartTotal: 0,
      placeOrder: false,
      isApproved: '',
      isOwnProduct: false,
      defaultAddress: {}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const { productDetailMessageId, addToCartMessageId, featuredMessageId, customerViewedMessageId, productRelatedMessageId, buyItAgainMessageId, frequentlyRepurchasedMessageId } = this.state
    let requestID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    let successResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if (productDetailMessageId === requestID) {
      if (successResponse) {
        this.setState({ productDetail: successResponse.data ?? {}, imageUrl: (successResponse?.data?.attributes?.images?.length > 0 ? successResponse?.data.attributes.images[0]?.url : ''), isApproved: successResponse?.account_is_approved, isOwnProduct: successResponse?.is_own_catalogue, defaultAddress: successResponse.default_address ?? {}})
        window.scrollTo(0, 0)
      }
    } else if (addToCartMessageId === requestID) {
      if (successResponse && successResponse?.data) {
        this.setState({ cartTotal: successResponse?.data?.attributes?.order_items_count });
        this.onRefetchCart()
        if (this.state.isRedirect) {
          this.props?.navigation?.navigate('ShoppingCartOrders')
        } else {
          this.setState({
            addToCartSuccessMessage: 'Successfully added.',
            quantity: 1,
            isRedirect: false
          })
        }

      } else {
        this.setState({
          addToCartMessage: successResponse.errors,
          quantity: 1
        })
      }
    } else if (featuredMessageId === requestID) {
      if (successResponse && successResponse?.data?.length > 0) {
        this.setState({ featured: successResponse.data})
      }
    } else if (customerViewedMessageId === requestID) {
      if (successResponse && successResponse?.data?.length > 0) {
        this.setState({ customerViewed: successResponse.data})
      }
    } else if (productRelatedMessageId === requestID) {
      if (successResponse && successResponse?.data?.length > 0) {
        this.setState({ productRelated: successResponse.data})
      }
    } else if (buyItAgainMessageId === requestID) {
      if (successResponse && successResponse?.data?.length > 0) {
        this.setState({ buyItAgain: successResponse.data})
      }
    } else if (frequentlyRepurchasedMessageId === requestID) {
      if (successResponse && successResponse?.data?.length > 0) {
        this.setState({ frequentlyRepurchased: successResponse.data})
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  componentDidMount(): any {
    this.getProductDetailsData();
    this.getFeatured();
    this.getCustomerViewed();
    this.getRelatedProducts();
    this.getBuyItAgain();
    this.getFrequentlyRepurchased();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    let previousId = prevProps.navigation.getParam("id");
    let newId = this.props.navigation.getParam("id");
    let isChangeId = previousId !== newId;

    if (previousId && newId && isChangeId) this.componentDidMount();
  }

  onRefetchCart() {
    const dispatch = this.props.reduxDispatch
    dispatch({ type: this.props.reduxState.actions.cart.REFETCH })
  }

  getProductDetailsData() {
    const requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.productDetailAPIURL + this.props.navigation.getParam('id') });
    this.setState({ productDetailMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFeatured() {
    const requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.featuredItemsAPIURL });
    this.setState({ featuredMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCustomerViewed() {
    const requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.customerReviwedAPIURL + this.props.navigation.getParam('id') });
    this.setState({ customerViewedMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRelatedProducts() {
    const requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.relatedProductsAPIURL + this.props.navigation.getParam('id') });
    this.setState({ productRelatedMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBuyItAgain() {
    const requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.buyItAgainAPIURL });
    this.setState({ buyItAgainMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFrequentlyRepurchased() {
    const requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.frequantlyRepurchasedAPIURL });
    this.setState({ frequentlyRepurchasedMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleQuantityChange(e: any) {
    this.setState({
      quantity: e.target.value
    });
  }

  addToCart(isRedirect = false) {
    this.setState({ isRedirect: isRedirect, addToCartMessage: '', addToCartSuccessMessage: ''})
    const addtoCartData = new FormData();
    addtoCartData.set('catalogue_id', this.props.navigation.getParam('id'));
    addtoCartData.set('quantity', this.state.quantity);
    addtoCartData.set('order_action', 'add');
    const requestMessage = GetApiMessage({ method: 'post', endpoint: configJSON.addToCartAPIURL, data: addtoCartData });
    this.setState({ addToCartMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  buyNow() {
    this.addToCart(true);
  }

  changeImage(url: any) {
    this.setState({ imageUrl: url });
  }

  openPopUp = (result: boolean) => {
    this.setState({ placeOrder: result });
  }

  closeModal() {
    this.setState({
      placeOrder: false
    });
  }
  // Customizable Area End
}
