// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


import GetApiMessage from "../../utilities/src/GetApiMessage";

import ApiUrls from "./config";

export interface Props {
  navigation: any;
  id: string;
  product: string,
  description: string,
  price: any,
  deal: any,
  bestSeller: any,
  resultDeal: any,
  slug: string,
  image: string,
  accountIsApproved: string,
  itemData: any;
  openApproved: any;
  reduxState: { actions: { cart: { REFETCH: string } } }
  reduxDispatch: {dispatch : Function}
}

interface S {
    addToCartMessageId: any;
    cartTotal: any;
}

interface SS {
  id: any;
}

export default class CommoncardController extends BlockComponent<
  Props,
  S,
  SS
> {
    constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
        addToCartMessageId: '',
        cartTotal: 0
      }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
        let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    }
    const { addToCartMessageId } = this.state
    let requestID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    let successResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
   if (addToCartMessageId === requestID) {
      if (successResponse && successResponse?.data) {
        const {itemData} = this.props;
        itemData.is_in_cart = true;
        this.onRefetchCart()
      } else {
        
      }
    }
  }

  onRefetchCart() {
    const { dispatch } = this.props.reduxDispatch
    dispatch({ type: this.props.reduxState.actions.cart.REFETCH })
  }

  addToCartFromList(catalogueId: any) {
    const addtoCartData = new FormData();
    addtoCartData.set('catalogue_id', catalogueId);
    addtoCartData.set('quantity', '1');
    addtoCartData.set('order_action', 'add');
    const requestMessage = GetApiMessage({ method: 'post', endpoint: ApiUrls.addToCartAPIURL, data: addtoCartData });
    this.setState({ addToCartMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
  // Customizable Area End