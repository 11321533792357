// Customizable Area Start
import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import { iconFacebook, iconInstagram, iconTwitter } from "./assets";

interface Props {}
interface SS {}
interface S {}

class FrontFooter extends BlockComponent<Props, S, SS> {
  render() {
    return (
      <footer className="frontFooter">
        <Grid
          container
          spacing={4}
          className="mb-5"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Grid item xs={12} sm={3}>
            <div className="title">Get to Know Us</div>
            <Link className="footer-links" to="/footer/about-us">
              About Us
            </Link>
            <Link className="footer-links" to="/footer/terms-of-use">
              Terms Of Use
            </Link>
            <Link className="footer-links" to="/footer/privacy-policy">
              Privacy Policy
            </Link>
            <Link
              className="footer-links"
              to="/footer/copyrights-and-trademarks"
            >
              Copyrights & Trademarks
            </Link>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className="title">Make Money with Us</div>
            <Link className="footer-links" to="/career">
              Careers
            </Link>
            <Link className="footer-links" to="/contact-us">
              Contact Us
            </Link>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className="title">Let Us Help You</div>
            <Link className="footer-links" to="/footer/faqs">
              FAQs
            </Link>
            <Link className="footer-links" to="/footer/returns-and-refunds">
              Returns &amp; Refunds
            </Link>
            <Link className="footer-links" to="/footer/payments-and-shippings">
              Payments &amp; Shippings
            </Link>
            <Link className="footer-links" to="/footer/help">
              Help
            </Link>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Link to={"/home"}>
              <img className="logo" src="/frontend/logo.png" alt="logo" />
            </Link>
            <div className="social-icons">
              <Link to="/">
                <img className="icons" src={iconFacebook} alt="facebook" />
              </Link>
              <Link to="/">
                <img className="icons" src={iconTwitter} alt="twitter" />
              </Link>
              <Link to="/">
                <img className="icons" src={iconInstagram} alt="instagram" />
              </Link>
            </div>
          </Grid>
        </Grid>

        <div className="copyright p-0 pb-3">
          Copyright 2023 Jeddah House Ecommerce | All Right Reserved
        </div>
        <a className="top" onClick={() => window.scrollTo(0, 0)}>
          <img alt="" src="/frontend/arrow-up-white.png" />
          Back to top
        </a>
        <a href="https://api.whatsapp.com/send?phone=919891054016&text=Hello" target='_BLANK'><img className='whatsapp' src='/frontend/whatsapp.png' alt='whatsapp'/></a>
      </footer>
    );
  }
}

export default FrontFooter;
// Customizable Area End
