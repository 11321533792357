// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";

import moment from "moment";
import GetApiMessage from "../GetApiMessage";
import ISuccessResponse from "../ISuccessResponse";
import ApiResponseMiddleware from "../ApiResponseMiddleware";
import GetErrorWithKeys from "../GetApiErrorsWithKeys";
import GetErrorMessages from "../GetErrorMessageFromResponse";
import BlockHelpers from "../../../utilities/src/BlockHelpers";

const configJs = require("./config");
const Strings = configJs.Strings;
const ApiUrls = configJs.ApiUrls;
const MappedErrorKeys = configJs.MappedErrorKeys;

interface Props {
  onClose: Function;
  onRefetch: Function;
  discountId: any;
}
interface SS {}
interface Item {
  photo: any;
  discount: string | any;
  quantity: number | any;
  expire_at: string | any;
  discount_type: string | any;
  payment_based: string | any;
  photoDoc: { name?: any } | any;
  accounts_id: number | string | any;
  catalogues_id: number | string | any;
  allow_free_shipping: boolean | string;
}
interface S {
  // Message ids
  getDiscountMessageId: string;
  getListMessageId: string;
  saveMessageId: string;
  editMessageId: string;

  // Arrays
  catalogueList: Array<any> | string;

  item: Item;
  validation: Item;
  isLoading: boolean;
}

class DiscountsController extends BlockComponent<Props, S, SS> {
  state = {
    // message ids
    getDiscountMessageId: "",
    getListMessageId: "",
    saveMessageId: "",
    editMessageId: "",

    // arrays
    catalogueList: [],

    isLoading: false,

    item: {
      discount: 0,
      quantity: 0,
      photo: null,
      photoDoc: null,
      accounts_id: null,
      catalogues_id: "",
      expire_at: moment(),
      discount_type: null,
      payment_based: null,
      allow_free_shipping: false,
    },
    validation: {
      photo: "",
      discount: "",
      quantity: "",
      photoDoc: "",
      expire_at: "",
      accounts_id: "",
      discount_type: "",
      catalogues_id: "",
      payment_based: "",
      allow_free_shipping: "",
    },
  };

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  receive(from: string, message: Message): void {
    if (!ApiResponseMiddleware(message)) return;

    const {
      saveMessageId,
      editMessageId,
      getListMessageId,
      getDiscountMessageId,
    } = this.state;

    let requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (successResponse) {
      if (requestId === getDiscountMessageId) {
        let {
          discount,
          expire_at,
          accounts_id,
          discount_type,
          catalogue_id,
          allow_free_shipping,
          payment_based,
          quantity_based,
          photo,
        } = successResponse?.data?.attributes || {};

        this.setState({
          item: {
            photo: photo,
            photoDoc: null,
            discount: discount,
            accounts_id: accounts_id,
            quantity: quantity_based,
            catalogues_id: catalogue_id,
            discount_type: discount_type,
            payment_based: payment_based,
            allow_free_shipping: allow_free_shipping,
            expire_at: moment(expire_at).format("YYYY-MM-DD HH:mm:ss"),
          },
        });
      } else if (requestId === getListMessageId) {
        this.setState({
          catalogueList: successResponse?.data.map((item: any) => {
            return { id: item.id, ...item.attributes };
          }),
        });
      } else if (requestId === saveMessageId) {
        this.setState({ isLoading: false });
        let errors = GetErrorWithKeys(successResponse, MappedErrorKeys);
        if (!errors) {
          BlockHelpers.showAlert(
            Strings.labels.success,
            Strings.message.discountSaved
          );
          this.props.onRefetch();
          this.props.onClose();
        } else {
          // @ts-ignore
          this.setState({ validation: errors });
          errors.extraMessages &&
            BlockHelpers.showAlert(Strings.labels.alert, errors.extraMessages);
        }
      } else if (requestId === editMessageId) {
        this.setState({ isLoading: false });
        let errors = GetErrorWithKeys(successResponse, MappedErrorKeys);
        if (!errors) {
          BlockHelpers.showAlert(
            Strings.labels.success,
            Strings.message.discountUpdated
          );
          this.props.onRefetch();
          this.props.onClose();
        } else {
          // @ts-ignore
          this.setState({ validation: errors });
          errors.extraMessages &&
            BlockHelpers.showAlert(Strings.labels.alert, errors.extraMessages);
        }
      }
    } else if (errorResponse) {
      let errors = GetErrorMessages(errorResponse);
      if (errors) BlockHelpers.showAlert(Strings.labels.alert, errors);
    }
  }

  componentDidMount(): any {
    super.componentDidMount();
    this.getProductList();
    let isEdit = this.props.discountId ? true : false;
    if (isEdit) this.getDiscount();
  }

  onChangeItem(key: string, value: any) {
    this.setState((prev: S) => ({
      ...prev,
      item: {
        ...prev.item,
        [key]: value,
      },
    }));
  }

  isValid() {
    let errors: any = {};
    const saveError = (key: string, value: string) => (errors[key] = value);

    const {
      catalogues_id,
      discount,
      discount_type,
      expire_at,
      photo,
      quantity,
      photoDoc,
      payment_based,
    } = this.state.item;

    if (!discount_type)
      saveError("discount_type", Strings.validation.discountType);
    if (!expire_at) saveError("expire_at", Strings.validation.expiry);
    if (!discount) {
      saveError("discount", Strings.validation.discount);
    } else if (
      discount_type === "percentage" &&
      (discount > 100 || discount <= 0)
    ) {
      saveError("discount", Strings.validation.percentageDiscount);
    } else if (discount_type === "flat" && discount <= 0) {
      saveError("discount", Strings.validation.flatDiscount);
    }

    if (!catalogues_id)
      saveError("catalogues_id", Strings.validation.catalogue);

    // if (Number(quantity) <= 0) {
    //   saveError("quantity", Strings.validation.quantity);
    // }
    if (!payment_based) {
      saveError("payment_based", Strings.validation.paymentBased);
    }
    if (!photo && !photoDoc) {
      saveError("photoDoc", Strings.validation.photo);
    }

    this.setState({ validation: errors });

    return !(Object.keys(errors).length > 0);
  }

  onSubmit() {
    if (!this.isValid()) return;
    if (this.state.isLoading) return;

    const accounts_id = localStorage.userId;
    const isEdit = this.props.discountId ? true : false;

    const {
      discount,
      expire_at,
      discount_type,
      catalogues_id,
      allow_free_shipping,
      payment_based,
      photoDoc,
      // quantity,
    } = this.state.item;

    let form = new FormData();
    form.set("discount", String(discount));
    form.set("expire_at", String(expire_at));
    form.set("quantity_based", String(1));
    // form.set("quantity_based", String(quantity));
    form.set("accounts_id", String(accounts_id));
    form.set("catalogue_id", String(catalogues_id));
    form.set("payment_based", String(payment_based));
    form.set("discount_type", String(discount_type));
    form.set("allow_free_shipping", String(allow_free_shipping));
    // @ts-ignore
    photoDoc && form.set("photo", photoDoc);

    let requestMessage: Message;

    if (isEdit) {
      requestMessage = GetApiMessage({
        data: form,
        method: "put",
        endpoint: ApiUrls.updateDiscount + this.props.discountId,
      });
      this.setState({ editMessageId: requestMessage.messageId });
    } else {
      requestMessage = GetApiMessage({
        data: form,
        method: "post",
        endpoint: ApiUrls.createDiscount,
      });
      this.setState({ saveMessageId: requestMessage.messageId });
    }

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getProductList() {
    let requestMessage = GetApiMessage({
      endpoint: ApiUrls.listOwnCatalogues,
    });
    this.setState({ getListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDiscount() {
    let requestMessage = GetApiMessage({
      endpoint: ApiUrls.showDiscount + this.props.discountId,
    });
    this.setState({ getDiscountMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

export default DiscountsController;

// Customizable Area End
