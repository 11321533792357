import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CommonCard from "../../FrontLayout/src/CommonCard.web";
import BrowsingHistory from "../../FrontLayout/src/BrowsingHistory.web";
import FrontPagination from "../../FrontLayout/src/Pagination.web";

// Customizable Area End

import ElasticSearchController, {
  Props,
  configJSON,
} from "./ElasticSearchController";

class ElasticSearch extends ElasticSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { brandsList, searchList, priceList, showSearch, slug, titleName, excludeOutOfStock, categoryName, locations, sort} = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <div className='HomePage'>
        <div className='frontContent'>
          {/* content */}
          {showSearch === 1 && (<div className='pt-5'><span className='heading'>Category: {categoryName}</span> </div>)}
          {showSearch === 2 && (<div className='pt-5'><span className='heading'>Result: {slug}</span> Price and other details may vary based on product size and colour</div>)}
          {showSearch === 3 && (<div className='pt-5'><span className='heading'>{titleName}</span></div>)}

          <div className='flexBox'>
            <div className='product-type'>
              <div className='result-flex'>
                {searchList?.data?.length > 0 && searchList?.data?.map((result: any, i: number) => (
                  <div className='result-columns' key={'searchList-item-' + result.id}>
                    <CommonCard price={result?.attributes?.price} image={result?.attributes?.images?.length > 0 ? result?.attributes?.images[0]?.url : '/frontend/product1.png'} product={result?.attributes?.name} description={result?.attributes?.description} deal={''} bestSeller={''} resultDeal={''} key={'common-card-item-' + result?.id} slug={result?.id} accountIsApproved={result?.attributes?.account_is_approved ?? ''} itemData={result}/>
                  </div>
                ))}
                {!searchList?.data && (<h3>Not Found.</h3>)}
              </div>

              {searchList?.data?.length > 0 && (
                <Grid container justifyContent="center">
                  <Grid item>
                    <br />
                    <FrontPagination
                      count={this.state.lastPage}
                      onChange={(page: number) => this.getSearchResult(page, excludeOutOfStock, sort )}
                    />
                  </Grid>,
                </Grid>
              )}
            </div>
            {showSearch != 3 && (
              <div>
                <select name={sort} onChange={(e)=>{this.handleSort(e)}} className={'mb-3 w-100 p-2'}>
                  <option value={'all'}>Sort</option>
                  <option value={'a-z'}>A to Z</option>
                  <option value={'z-a'}>Z to A</option>
                  <option value={'lowToHigh'}>Low to High</option>
                  <option value={'highToLow'}>High to Low</option>
                </select>
                <div className='filter'>
                  <div className='filter-head'>Filter <img src='/frontend/filter.png' alt='filter' /></div>
                  <div className='filter-body'>
                    <div>
                      <div className='title'>Brands</div>
                      {brandsList?.length > 0 && brandsList?.map((result: any, i: number) => (
                        <label className='filter-inputs' key={'brandList-item' + result.id}><input className='input-check' type='checkbox' checked={result?.attributes?.isChecked} value={result?.attributes?.id} onChange={(e) => { this.handleFilters(e, i, 'brands') }} />{result?.attributes?.name}</label>
                      ))}
                    </div>
                    <div>
                      <div className='title'>Price</div>
                      {priceList?.length > 0 && priceList?.map((result: any, i: number) => (
                        <label key={'price-list-item-' + i} className='filter-inputs'><input className='input-check' checked={result?.isChecked} type='checkbox' onChange={(e) => { this.handleFilters(e, i, 'price') }} /> AED {result?.min} to AED {(result?.max === 1000000) ? 'Above' : result?.max}</label>
                      ))}
                    </div>
                    <div>
                      <div className='title'>Locations</div>
                      {locations.length > 0 && locations.map((result: any, i: number) => (
                        <label className='filter-inputs' key={'locations-item' + i}><input className='input-check' type='checkbox' checked={result?.isChecked} value={result.name} onChange={(e) => { this.handleFilters(e, i, 'locations') }} />{result.value}</label>
                      ))}
                    </div>
                    <div>
                      <div className='title'>Top Rated</div>
                      <img className='mb-2' src='/frontend/rating.png' alt='rating' />
                    </div>
                    <div>
                      <div className='title'>Delivery Day</div>
                      <label className='filter-inputs'><input className='input-check' type='checkbox' /> Get it Today</label>
                      <label className='filter-inputs'><input className='input-check' type='checkbox' /> Get it by Tommorow</label>
                    </div>
                    <div>
                      <div className='title'>Discounts and Offers</div>
                      <label className='filter-inputs'><input className='input-check' type='checkbox' /> 10% off</label>
                      <label className='filter-inputs'><input className='input-check' type='checkbox' /> 25% off</label>
                    </div>
                    <div>
                      <div className='title'>Availability</div>
                      <label className='filter-inputs'><input className='input-check' type='checkbox' name='excludeOutOfStock' checked={!excludeOutOfStock} onChange={(e) => { this.handleStockFilter(e)}} /> Include Out of Stock</label>
                    </div>
                    <div className="text-center">
                    <button className='filled-btn' onClick={() => {this.clearButton()}}>Clear Filters</button>
                    </div>
                  </div>
                </div>
              </div>)}
          </div>
          <BrowsingHistory />
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default ElasticSearch
// Customizable Area End
