// Customizable Area Start

import React from "react";
import { Chart } from "react-google-charts";

import ReportWrapper from "./ReportWrapper";
import AdHocReportingController from "./AdHocReportingController";

const configJs = require("./config");
const Strings = configJs.Strings;

const options = {
  isStacked: true,
  chartArea: { width: "50%" },
  legend: { position: "top", alignment: "start" },
  colors: ['#FF2300','#00B900'],
  hAxis: {
    minValue: 0,
  },
};

class CyLyGraph extends AdHocReportingController {
  render() {
    const { cyLyDownloadLink } = this.state;
    return (
      <ReportWrapper
        visibleMonthSelector={false}
        mainTitle={Strings.labels.cyLy}
        downloadLink={cyLyDownloadLink}
        onChangeFilter={() => null}
      >
        <Chart
          // @ts-ignore
          chartType="Bar"
          options={options}
          data={this.getCyLyChartData()}
        />
      </ReportWrapper>
    );
  }
}

export default CyLyGraph;

// Customizable Area End
