import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ToWords } from 'to-words';
import Moment from "moment";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import InvoiceBillingController, {
  Props,
  configJSON,
} from "./InvoiceBillingController";

export default class InvoiceBilling extends InvoiceBillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const toWords = new ToWords();
    const { invoice } = this.state;
    const { sold_by } = invoice?.data?.attributes || {};

    if (!invoice) return <>No Invoice Found</>;

    // Customizable Area End
    return (
      // Customizable Area Start
      <div className="invoice">
        <header className="invoice-header">
          <div className="invoice-logo">
            Company
            <br />
            Logo
          </div>
          <div className="invoice-heading">
            Tax Invoice/Bill of Supply/Cash Memo
            <br />
            <span>(Original for Recipient)</span>
          </div>
        </header>
        <section className="invoice-content">
          <div className="invoice-flex">
            <div className="invoice-column column1">
              <h5 className="invoice-title">Sold By:</h5>
              <p className="invoice-data">{sold_by?.company_name}</p>
              <p className="invoice-data">{sold_by?.address}</p>
              <p className="invoice-data">Zipcode : {sold_by?.zip_code}</p>
            </div>
            <div className="invoice-column column2">
              <h5 className="invoice-title">Billing Address:</h5>
              <p className="invoice-data">
                {invoice.data?.attributes?.billing_address}
              </p>
            </div>
          </div>
          <div className="invoice-flex">
            <div className="invoice-column column1">
              <h6 className="invoice-title">
                PAN No.:{" "}
                <span className="invoice-data">
                  {invoice.meta?.company_pan}
                </span>
              </h6>
              <h6 className="invoice-title">
                GST Registration No.:{" "}
                <span className="invoice-data">
                  {invoice.meta?.company_gst}
                </span>
              </h6>
            </div>
            <div className="invoice-column column2">
              <h5 className="invoice-title">Shipping Address:</h5>
              <p className="invoice-data">
                {invoice.data?.attributes?.shipping_address}
              </p>

              <h6 className="invoice-title">
                Place of Supply:{" "}
                <span className="invoice-data">
                  {invoice.data?.attributes?.place_of_supply}
                </span>
              </h6>
              <h6 className="invoice-title">
                Place of Delivery:{" "}
                <span className="invoice-data">
                  {invoice.data?.attributes?.place_of_delivery}
                </span>
              </h6>
            </div>
          </div>
          <div className="invoice-flex">
            <div className="invoice-column column1">
              <h6 className="invoice-title">
                Order Number:{" "}
                <span className="invoice-data">
                  #{invoice.data?.attributes?.order_number}
                </span>
              </h6>
              <h6 className="invoice-title">
                Order Date:{" "}
                <span className="invoice-data">
                  {invoice.data?.attributes?.order_date ? Moment(invoice.data?.attributes?.order_date).format('DD.MM.YYYY') : ''}
                </span>
              </h6>
            </div>
            <div className="invoice-column column2">
              <h6 className="invoice-title">
                Invoice Number:{" "}
                <span className="invoice-data">
                  #{invoice.data?.attributes?.invoice_number}
                </span>
              </h6>
              <h6 className="invoice-title">
                Invoice Detail:{" "}
                <span className="invoice-data">
                  {invoice.data?.attributes?.invoice_details}
                </span>
              </h6>
              <h6 className="invoice-title">
                Invoice Date.:{" "}
                <span className="invoice-data">
                  {invoice.data?.attributes?.invoice_date}
                </span>
              </h6>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Description</th>
                  <th>Unit Price</th>
                  <th>Qty</th>
                  <th>Net Amount</th>
                  <th>Tax Rate</th>
                  <th>Tax Type</th>
                  <th>Tax Amount</th>
                  <th>Total Amount</th>
                </tr>
              </thead>
              <tbody>
                {invoice.data?.attributes?.order_items?.length > 0 &&
                  invoice.data?.attributes?.order_items?.map((result: any, i: number) => (
                    <tr key={'invoice' + i}>
                      <td>{i + 1}</td>
                      <td>
                        {result?.attributes?.catalogue?.attributes?.name}
                      </td>
                      <td>AED {result?.attributes?.unit_price}</td>
                      <td>{result?.attributes?.quantity}</td>
                      <td>AED {result?.attributes?.total_price}</td>
                      <td>15% + 15%</td>
                      <td>VAT</td>
                      <td>AED 0</td>
                      <td>AED {result?.attributes?.total_price}</td>
                    </tr>
                  ))}
                <tr>
                  <td className="blue-text" colSpan={2}>
                    TOTAL
                  </td>
                  <td colSpan={7} className="text-end blue-text">
                    AED {invoice.data?.attributes?.total}
                  </td>
                </tr>
                <tr>
                  <td className="invoice-title" colSpan={9}>
                    Amount in Words: {invoice.data?.attributes?.total ? toWords.convert(invoice.data?.attributes?.total) : ''}
                  </td>
                </tr>
                <tr>
                  <td className="invoice-title" colSpan={9}>
                    For {sold_by?.company_name}:
                    <br />
                    <br />
                    <br />
                    Authorized Signatory{" "}
                  </td>
                </tr>
              </tbody>
            </table>
            <h5 className="mb-3">
              Whether tax is payable under reverse charges - No
            </h5>
            <table className="table">
              <tbody>
                <tr>
                  <td className="p-3">Payment Transaction Id #{invoice.data?.attributes?.order_transaction}</td>
                  <td className="p-3">{invoice.data?.attributes?.order_date ? Moment(invoice.data?.attributes?.order_date).format('D/MM/YY, HH:mm:ss') : ''} hrs</td>
                  <td className="p-3">Invoice Value AED {invoice.data?.attributes?.total}</td>
                  <td className="p-3">
                    Mode of Payment: {invoice.data?.attributes?.payment_type}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <footer className="invoice-footer">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </footer>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
