// Customizable Area Start
import { ReactNode } from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";

interface Props {
  mainTitle: string;
  downloadLink: string;
  currentMonth?: string;
  onChangeMonth?: Function;
  children: any | ReactNode;
  onChangeFilter?: Function;
  visibleMonthSelector: boolean;
}
interface State {
  toggleFilter: boolean;
  currentFilter: String;
}
interface SS {}

class ReportWrapperController extends BlockComponent<Props, State, SS> {
  state = { toggleFilter: false, currentFilter: "Monthly" };

  onChangeFilter(filter: String) {
    const { onChangeFilter } = this.props;
    this.setState({ currentFilter: filter });
    if (onChangeFilter) onChangeFilter(filter);
  }
}

export default ReportWrapperController;

// Customizable Area End
