// Customizable Area Start

import React from "react";
import { Helmet } from "react-helmet";
import {
  styled,
  Box,
  Grid,
  Avatar,
  Select,
  Checkbox,
  MenuItem,
  Typography,
} from "@material-ui/core";

import NoDataTd from "../Shared/NoDataTd";
import StyledHR from "../Shared/StyledHR";
import Pagination from "../Shared/Pagination";
import TitleHeading from "../Shared/TitleHeading";
import RoundedButton from "../Shared/RoundedButton";

import GetDateString from "../GetDateString";
import NotificationsController from "./NotificationsController";

const configJs = require("./config");
const Strings = configJs.Strings;

export const SelectActions = [
  { type: "read", title: Strings.actions.read },
  { type: "unread", title: Strings.actions.unread },
  { type: "delete", title: Strings.actions.delete },
  { title: Strings.actions.select },
];

class Notifications extends NotificationsController {
  render() {
    const { deleteModalFlag, notificationList } = this.state;
    return (
      <div data-test-id="notification-screen">
        <Helmet>
          <title>{Strings.header.title}</title>
        </Helmet>
        {deleteModalFlag && (
          <div className="modalBox">
            <div className="modalContent">
              <button
                data-test-id="btn-close"
                type="button"
                className="btnClose"
                onClick={() => this.setState({ deleteModalFlag: null })}
              >
                &times;
              </button>
              <div className="modalBody">
                <div className="message">
                  <h4>{Strings.message.deleteSuccessfully}</h4>
                  <div className="buttonBox">
                    <button
                      data-test-id="btn-goBack"
                      className="buttons filled"
                      type="button"
                      onClick={() =>
                        this.setState({
                          deleteModalFlag: null,
                        })
                      }
                    >
                      {Strings.actions.goBack}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <Grid container spacing={1} justifyContent="space-between">
          <Grid item>
            <TitleHeading text={Strings.labels.title} />
          </Grid>

          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <StyledSelect
                  data-test-id="field-select"
                  value={this.state.selectAction}
                  displayEmpty
                  variant="outlined"
                  onChange={(e: any) => this.onChangeAction(e.target.value)}
                >
                  {SelectActions.map(({ title, type }) => (
                    <MenuItem key={"select-item-type-" + type} value={type}>
                      {title}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </Grid>
              <Grid item>
                <RoundedButton
                  data-test-id="btn-clearAll"
                  onClick={() => this.onClearAll()}
                  text={Strings.actions.clearAll}
                  isActive={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <StyledHR />
        <Container p={1}>
          {notificationList.length === 0 && (
            <table style={{ width: "100%" }}>
              <tr key={"no-data-information"}>
                <NoDataTd>{Strings.message.noData}</NoDataTd>
              </tr>
            </table>
          )}
          {notificationList.map((item: any) => {
            const { headings, contents, created_by, created_at, id, is_read } =
              item;

            const { last_name, first_name, created_by_image } = created_by;

            const userName = first_name + " " + last_name;

            const isSelected =
              // @ts-ignore
              this.state.selectedNotifications.indexOf(id) !== -1;

            return (
              <FlexContainer
                key={"flex-container-item-" + item.id}
                data-test-id={"notification-" + item.id}
                onClick={() => this.onSelectNotification(id)}
              >
                {isSelected && <StyledCheckBox checked />}

                <Avatar src={created_by_image}>
                  {String(first_name).toUpperCase().charAt(0)}
                </Avatar>

                <FlexGrowContainer>
                  <NotificationTitle active={!is_read}>
                    {userName}
                  </NotificationTitle>
                  <BodyText>{headings}</BodyText>
                  <BodyText>{contents}</BodyText>
                </FlexGrowContainer>

                <TimeText>{GetDateString(created_at)}</TimeText>
              </FlexContainer>
            );
          })}

          {notificationList.length !== 0 && (
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Pagination
                  data-test-id="pagination"
                  currentPage={this.state.currentPage}
                  lastPage={this.state.lastPage}
                  onChangePage={(page: number) => this.onChangePage(page)}
                />
              </Grid>
            </Grid>
          )}
        </Container>
      </div>
    );
  }
}

const StyledCheckBox: any = styled(Checkbox)({
  "&.MuiCheckbox-colorSecondary.Mui-checked": {
    color: "var(--navy)",
  },
});

const NotificationTitle: any = styled("h6")((props: any) => ({
  paddingTop: 3,
  color: props.active ? "black" : "#bbb",
}));

const StyledSelect: any = styled(Select)({
  height: 34,
  marginTop: 5,
  fontWeight: 500,
  paddingLeft: 10,
  paddingBottom: 3,
  "& .MuiOutlinedInput-notchedOutline": {
    border: "2px solid var(--navy)",
  },
  "& .MuiSelect-root": {
    padding: 0,
    minHeight: 0,
    paddingRight: 32,
    lineHeight: "unset",
  },
});

const BodyText: any = styled(Typography)({
  fontWeight: 500,
  fontSize: 14,
  color: "#bbb",
});

const TimeText: any = styled(BodyText)({
  whiteSpace: "nowrap",
});

// const TimeTitle = styled(Typography)({
//     fontWeight: 600,
//     color: 'black',
//     fontSize: 18,
//     paddingBottom: 14,
// })

const Container: any = styled(Box)({
  boxShadow: "1px 1px 10px var(--boxShadow)",
});

const FlexContainer: any = styled(Box)({
  gap: 8,
  display: "flex",
  paddingBottom: 8,
  marginBottom: 16,
  cursor: "pointer",
  alignItems: "flex-start",
  borderBottom: "1px solid lightgray",
});

const FlexGrowContainer: any = styled(Box)({
  flexGrow: 1,
});

export default Notifications;

// Customizable Area End
