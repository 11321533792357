// Customizable Area Start
import React from "react";
import CommonCarousel from "../../FrontLayout/src/CommonCarousel.web";
import BrandsController from "./BrandsControllers.web";
import ShopCategory from "./ShopCategory.web";

export default class Brands extends BrandsController {
  render() {
    const { brandsPageList } = this.state;
    const divColor:Array<string> = [];
    divColor[5] = 'pink-box';
    divColor[6] = 'purple-box';
    divColor[7] = 'blue-box';
    divColor[8] = 'green-box';
    return (
      <>
        {brandsPageList.length > 0 ? (
          <div className="frontContent">
            {/* hero */}
            <div className="brands-name">
              <h6 className="heading text-start">Brands</h6>
              <div className="row m-0 p-0">
                {brandsPageList.map((result: any, i: number ) => 
                (i<4) ? (
                    <div className="col-md-3 col-sm-6 col-12" key={'brandsBanner' + i}>
                    <a href={"/d/brand/" + result.id}>
                      <img
                        className="w-100 mb-3" style={{height: '300px'}}
                        alt={result.attributes.name}
                        src={result.attributes.images?.url}
                      />
                      <h6 className="heading m-0">
                        <small>{result.attributes.name}</small>
                      </h6>
                    </a>
                  </div>
                ) : '')}
                
              </div>
            </div>
            {/* content */}
            {brandsPageList[4]?.attributes.catalogues.data.length > 0 && (<CommonCarousel name={brandsPageList[4].attributes.name} items={brandsPageList[4].attributes.catalogues.data} sectionSlug={'brand/' + brandsPageList[4].id} bestSeller={''} resultDeal={''}/>)}
            {brandsPageList.length > 4 ? ( <div>
              <h6 className="heading text-start">Other Brands</h6>
              <div className="row m-0 px-3">
              {brandsPageList.map((result: any, i: number ) => 
              ((i> 4) && (i<9)) ? (
                <div className="col-sm-6 mb-3" key={'brandsSmallSection' + i}>
                  <div className={divColor[i]}>
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                        <h4>{result.attributes.name}</h4>
                        <p></p>
                      </div>
                      <div className="col-sm-6">
                      <a href={"/d/brand/" + result.id}> <img
                          className="other-brands"
                          alt={result.attributes.name}
                          src={result.attributes.images?.url}
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
                ) : '')}
              </div>
            </div>) : ''}  
            {brandsPageList.length > 11 ? (<div>
              <div className="row m-0 px-3">
                <div className="col-sm-6 mb-3">
                  <h6 className="heading text-start ps-0">{brandsPageList[9].attributes.name}</h6>
                  <a href={"/d/brand/" + brandsPageList[9].id}><img className="w-100" style={{height: '300px', objectFit: 'cover'}} alt="offer" src={brandsPageList[9].attributes.images?.url} /></a>
                </div>
                <div className="col-sm-6">
                  <h6 className="heading text-start ps-0">{brandsPageList[10].attributes.name}</h6>
                  <a href={"/d/brand/" + brandsPageList[10].id}><img className="w-100" style={{height: '300px', objectFit: 'cover'}} alt="offer" src={brandsPageList[10].attributes.images?.url} /></a>
                </div>
              </div>
            </div>) : ''}
            <div className="mx-4">
            {brandsPageList[11]?.attributes.catalogues.data.length > 0 && (<CommonCarousel name={brandsPageList[11].attributes.name} items={brandsPageList[4].attributes.catalogues.data} sectionSlug={'brand/' + brandsPageList[4].id} bestSeller={''} resultDeal={''}/>)}
            </div>
            {brandsPageList?.length > 0 && (
              <ShopCategory items={brandsPageList} type="Brands" />
            )}
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}
// Customizable Area End
