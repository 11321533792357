// Customizable Area Start
import React from "react";
import { Helmet } from "react-helmet";
import {
  styled,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";

import RoundedButton from "../Shared/RoundedButton";
import ContactUsController from "./ContactUsController";

const configJs = require("./config");
const Strings = configJs.Strings;

class ContactUs extends ContactUsController {
  render() {
    const { modalFlag, contactUsList, validation, data } = this.state;

    return (
      <>
        <Helmet>
          <title>{Strings.header.title}</title>
        </Helmet>
        <TitleHeading>{Strings.labels.contact}</TitleHeading>
        <BlueText>{Strings.message.forAllQueries}</BlueText>
        <br />

        <Grid container data-test-id="contact-us-screen">
          <Grid item xs={8}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <StyledTextField
                  data-test-id="first_name"
                  fullWidth
                  variant="outlined"
                  error={validation.first_name ? true : false}
                  helperText={validation.first_name}
                  label={Strings.labels.firstName}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={data.first_name}
                  onChange={(e) =>
                    this.onChangeItem("first_name", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <StyledTextField
                  data-test-id="last_name"
                  fullWidth
                  variant="outlined"
                  error={validation.last_name ? true : false}
                  helperText={validation.last_name}
                  label={Strings.labels.lastName}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={data.last_name}
                  onChange={(e) =>
                    this.onChangeItem("last_name", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <StyledTextField
                  data-test-id="email"
                  fullWidth
                  variant="outlined"
                  error={validation.email ? true : false}
                  helperText={validation.email}
                  label={Strings.labels.email}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={data.email}
                  onChange={(e) => this.onChangeItem("email", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledTextField
                  data-test-id="phone"
                  fullWidth
                  variant="outlined"
                  error={validation.phone ? true : false}
                  helperText={validation.phone}
                  label={Strings.labels.phone}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={data.phone}
                  onChange={(e) => this.onChangeItem("phone", e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  data-test-id="reason"
                  fullWidth
                  variant="outlined"
                  error={validation.reason ? true : false}
                  helperText={validation.reason}
                  select
                  placeholder={Strings.labels.selectReason}
                  label={Strings.labels.howCanIHelpYou}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    placeholder: Strings.labels.selectReason,
                  }}
                  value={data.reason}
                  onChange={(e) => this.onChangeItem("reason", e.target.value)}
                >
                  {contactUsList?.map((item: any) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.query}
                      </MenuItem>
                    );
                  })}
                </StyledTextField>
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  data-test-id="description"
                  fullWidth
                  variant="outlined"
                  error={validation.description ? true : false}
                  helperText={validation.description}
                  label={Strings.labels.description}
                  multiline
                  minRows={3}
                  value={data.description}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    this.onChangeItem("description", e.target.value)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <br />

        <RoundedButton
          data-test-id="btn-submit"
          onClick={() => this.onSubmit()}
          isActive={true}
          text={Strings.actions.continue}
        />

        {modalFlag && (
          <div className="modalBox">
            <div className="modalContent">
              <button
                data-test-id="btn-close"
                onClick={() =>
                  this.setState({
                    modalFlag: false,
                    selectedFaqId: null,
                  })
                }
                type="button"
                className="btnClose"
              >
                &times;
              </button>
              <div className="modalBody">
                <br />
                <br />
                <h6 className="title" style={{ textAlign: "center" }}>
                  {Strings.message.sentSuccess}
                </h6>
                <br />
                <div className="buttonBox">
                  <button
                    data-test-id="btn-goBack"
                    onClick={() =>
                      this.setState({
                        modalFlag: false,
                        selectedFaqId: null,
                      })
                    }
                    className="buttons filled"
                    type="button"
                  >
                    {Strings.actions.goBack}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const StyledTextField = styled(TextField)({
  "& .MuiFormLabel-root": {
    color: "var(--content)",
    padding: "0px 4px",
    fontSize: 18,
    marginTop: -5,
    background: "white",
    letterSpacing: "unset",
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "var(--navy)",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--navy)",
  },
});

const TitleHeading = styled(Typography)({
  fontSize: 26,
  fontWeight: 500,
  paddingBottom: 8,
  color: "var(--navy)",
});

const BlueText = styled(Typography)({
  fontSize: 14,
  fontWeight: 500,
  color: "rgb(44,131,182)",
});

export default ContactUs;

// Customizable Area End
