// Customizable Area Start

import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";

import phoneValidator from "phone";

import GetApiMessage from "../GetApiMessage";
import GetErrorMessages from "../GetApiErrorsWithKeys";
import ApiResponseMiddleware from "../ApiResponseMiddleware";
import ISuccessResponse from "../ISuccessResponse";
import BlockHelpers from "../../../utilities/src/BlockHelpers";

const configJs = require("./config");
const Strings = configJs.Strings;
const ApiUrls = configJs.ApiUrls;
const MappedErrorMessages = configJs.MappedErrorMessages;

interface Props {
  navigation:
    | {
        navigate: Function;
      }
    | any;
}
interface SS {}
interface S {
  // other
  viewApiCallId: any;
  apiCallId: any;
  buttonLoading: boolean;
  // form states
  dob: any;
  firstName: any;
  lastName: any;
  name: any;
  city: any;
  email: any;
  state: any;
  phone: any;
  address: any;
  pinCode: any;
  password: any;
  confirmPassword: any;
  // form validation
  validation: {
    dob: any;
    name: any;
    city: any;
    email: any;
    state: any;
    phone: any;
    address: any;
    pinCode: any;
    password: any;
    firstName: any;
    lastName: any;
    confirmPassword: any;
  };
}

class EditProfileController extends BlockComponent<Props, S, SS> {
  state: S = {
    // other
    viewApiCallId: "",
    apiCallId: "",
    buttonLoading: false,
    // form states
    dob: "",
    firstName: "",
    lastName: "",
    name: "",
    city: "",
    email: "",
    state: "",
    phone: "",
    address: "",
    pinCode: "",
    password: "",
    confirmPassword: "",
    // form validation
    validation: {
      dob: "",
      name: "",
      city: "",
      email: "",
      state: "",
      phone: "",
      address: "",
      pinCode: "",
      password: "",
      firstName: "",
      lastName: "",
      confirmPassword: "",
    },
  };

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    this.getUserData();
  }

  getUserData() {
    const requestMessage = GetApiMessage({
      method: "get",
      endpoint: ApiUrls.viewProfile,
    });

    this.setState({
      viewApiCallId: requestMessage.messageId,
      apiCallId: "",
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async receive(from: string, message: Message) {
    if (!ApiResponseMiddleware(message)) return;

    let requestID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (this.state.viewApiCallId === requestID) {
      let successResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (successResponse) {
        const {
          dob,
          user_name,
          first_name,
          last_name,
          email,
          business_pin_code,
          full_phone_number,
          business_state,
          business_address,
          business_city,
        } = successResponse?.data?.attributes;

        let name = user_name;
        if (!user_name) name = first_name + " " + last_name;

        this.setState({
          dob: dob,
          firstName: first_name,
          lastName: last_name,
          city: business_city,
          email,
          password: "",
          confirmPassword: "",
          phone: full_phone_number,
          state: business_state,
          pinCode: business_pin_code,
          address: business_address,
          name: name,
        });
      }
    }

    if (this.state.apiCallId === requestID) {
      this.setState({ buttonLoading: false });
      const { status, message } = successResponse;

      if (status === 422) {
        let errors =
          message
            ?.map((item: any) => {
              let key = Object.keys(item)[0];
              return item[key];
            })
            .join(", ") ?? "";

        BlockHelpers.showAlert(Strings.labels.errors, errors);
        return;
      }

      if (successResponse) {
        let errorMessage = GetErrorMessages(message, MappedErrorMessages);
        if (errorMessage) {
          // @ts-ignore
          this.setState({ validation: errorMessage });
          BlockHelpers.showAlert(
            Strings.labels.errors,
            errorMessage.extraMessages
          );
        } else {
          BlockHelpers.showAlert(
            Strings.labels.success,
            Strings.message.profileSaved
          );
          this.props.navigation.navigate("Vendor", {
            page: "profile",
          });
        }
      }
    }
  }

  onChangeValue(key: any, value: any) {
    this.setState({
      ...this.state,
      [key]: value,
    });
  }

  isValid() {
    const emailRegexPattern =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    const {
      firstName,
      lastName,
      address,
      city,
      confirmPassword,
      password,
      dob,
      email,
      phone,
      pinCode,
      state,
    } = this.state;

    // @ts-ignore
    let validationErrors = {
      dob: "",
      city: "",
      email: "",
      state: "",
      phone: "",
      address: "",
      pinCode: "",
      password: "",
      confirmPassword: "",
    };

    const onAddMessageToValidation = (key: any, value: any) => {
      // @ts-ignore
      validationErrors[key] = value;
    };

    if (!dob) onAddMessageToValidation("dob", Strings.validation.dob);
    if (!city) onAddMessageToValidation("city", Strings.validation.city);

    if (!firstName)
      onAddMessageToValidation("firstName", Strings.validation.firstName);
    if (!lastName)
      onAddMessageToValidation("lastName", Strings.validation.lastName);
    if (!email) onAddMessageToValidation("email", Strings.validation.email);
    else if (!email.match(emailRegexPattern)) {
      onAddMessageToValidation("email", Strings.validation.validEmail);
    }

    if (!phone) onAddMessageToValidation("phone", Strings.validation.phone);
    else {
      const { isValid } = phoneValidator(phone, { country: "ARE" });
      if (!isValid)
        onAddMessageToValidation("phone", Strings.validation.validPhone);
    }
    if (!state) onAddMessageToValidation("state", Strings.validation.state);
    if (!address)
      onAddMessageToValidation("address", Strings.validation.address);
    if (!pinCode)
      onAddMessageToValidation("pinCode", Strings.validation.pinCode);

    if (password) {
      if (!confirmPassword) {
        onAddMessageToValidation(
          "confirmPassword",
          Strings.validation.confirmPassword
        );
      } else if (password !== confirmPassword) {
        onAddMessageToValidation(
          "confirmPassword",
          Strings.validation.confirmPasswordNotMatch
        );
      }
    }

    // @ts-ignore
    this.setState({ validation: validationErrors });

    for (const validationKey in validationErrors) {
      // @ts-ignore
      if (validationErrors[validationKey]) return false;
    }

    return true;
  }

  onSubmit() {
    if (!this.isValid()) return;

    this.setState({ buttonLoading: true });

    const {
      dob,
      name,
      city,
      email,
      state,
      phone,
      password,
      address,
      pinCode,
      firstName,
      lastName,
    } = this.state;

    const form = new FormData();

    form.set("first_name", firstName);
    form.set("last_name", lastName);
    form.set("dob", dob);
    form.set("email", email);
    form.set("user_name", name);
    form.set("business_city", city);
    form.set("business_state", state);
    form.set("full_phone_number", phone);
    form.set("business_address", address);
    form.set("business_pin_code", pinCode);

    if (password) form.set("password", password);

    const ApiData: any = {
      method: "patch",
      endpoint: ApiUrls.editProfile,
      data: form,
    };

    const requestMessage = GetApiMessage(ApiData);

    this.setState({ apiCallId: requestMessage.messageId });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

export default EditProfileController;

// Customizable Area End
