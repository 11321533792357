// Customizable Area Start
import moment from "moment";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";

import GetApiMessage from "../GetApiMessage";
import ApiResponseMiddleware from "../ApiResponseMiddleware";

const configJs = require("./config");
const ApiUrls = configJs.ApiUrls;
const Strings = configJs.Strings;

export interface ISalesByDistributionRow {
  month: string;
  card_payment: number;
  sadad_payment: number;
  cash_on_delivery: number;
}

export interface IOrderCountRow {
  month: string;
  average: number;
  net_sales: number;
  percentage: number;
  item_count: number;
  transaction_count: number;
}

export interface ICyLyRow {
  month: string;
  last_year: number;
  current_year: number;
}

interface IOrderTotals {
  total_average: number;
  total_net_sales: number;
  total_item_count: number;
  total_percentage: number;
  total_transaction_count: number;
}

interface Props {}
interface State {
  cyLyList: Array<ICyLyRow>;
  inventoryList: Array<any>;
  orderCountList: Array<IOrderCountRow>;
  salesByDistributionList: Array<ISalesByDistributionRow>;
  orderCountTotals: IOrderTotals;

  // download links
  cyLyDownloadLink: string;
  inventoryDownloadLink: string;
  orderCountDownloadLink: string;
  salesByDistributionDownloadLink: string;

  // ApiIds
  salesByDistributionMessageId: string;
  inventoryReportMessageId: string;
  orderCountMessageId: string;
  cyLyMessageId: string;

  // current Month
  currentMonthIndex: number;
  monthList: Array<string>;
}
interface SS {}

class AdHocReportingController extends BlockComponent<Props, State, SS> {
  state = {
    cyLyList: [],
    inventoryList: [],
    orderCountList: [],
    salesByDistributionList: [],
    orderCountTotals: {
      total_average: 0,
      total_net_sales: 0,
      total_item_count: 0,
      total_percentage: 0,
      total_transaction_count: 0,
    },

    // download links
    cyLyDownloadLink: "",
    inventoryDownloadLink: "",
    orderCountDownloadLink: "",
    salesByDistributionDownloadLink: "",

    // ApiIds
    salesByDistributionMessageId: "",
    inventoryReportMessageId: "",
    orderCountMessageId: "",
    cyLyMessageId: "",

    // change month logic
    currentMonthIndex: -1,
    monthList: [],
  };

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  receive(from: string, message: Message): void {
    if (!ApiResponseMiddleware(message)) return;

    const {
      cyLyMessageId,
      orderCountMessageId,
      inventoryReportMessageId,
      salesByDistributionMessageId,
    } = this.state;

    let requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (successResponse) {
      switch (requestId) {
        case orderCountMessageId:
          this.setState({
            orderCountTotals: successResponse.total,
            orderCountList: successResponse.data ?? [],
            orderCountDownloadLink: successResponse.download_data,
          });
          break;

        case cyLyMessageId:
          this.setState({
            cyLyList: successResponse.data ?? [],
            cyLyDownloadLink: successResponse.download_data,
          });
          break;

        case salesByDistributionMessageId:
          this.setState({
            salesByDistributionList: successResponse.data ?? [],
            salesByDistributionDownloadLink: successResponse.download_data,
          });
          break;

        case inventoryReportMessageId:
          this.setState({
            inventoryList: successResponse.data ?? [],
            inventoryDownloadLink: successResponse.download_data,
          });
          break;
      }
    }
  }

  componentDidMount(): any {
    super.componentDidMount();

    let months = [];
    for (let i = 0; i <= 11; i++)
      months.push(moment(new Date(2023, i, 1)).format("MMMM").toLowerCase());

    let currentMonth = moment().format("MMMM").toLowerCase();

    this.setState({
      monthList: months,
      currentMonthIndex: months.indexOf(currentMonth),
    });

    this.getSalesByDistribution();
    this.getInventoryReport();
    this.getOrderCountData();
    this.getCyLy();
  }

  getSalesByDistribution() {
    let requestMessage = GetApiMessage({
      endpoint: ApiUrls.salesByDistributionApi,
    });
    this.setState({ salesByDistributionMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getOrderCountData() {
    let requestMessage = GetApiMessage({ endpoint: ApiUrls.getOrderCountApi });
    this.setState({ orderCountMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCyLy() {
    let requestMessage = GetApiMessage({ endpoint: ApiUrls.cyLyApi });
    this.setState({ cyLyMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInventoryReport() {
    let requestMessage = GetApiMessage({
      endpoint: ApiUrls.inventoryReportApi,
    });
    this.setState({ inventoryReportMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCyLyChartData() {
    const { cyLyList } = this.state;

    if (cyLyList.length > 0) {
      const data: Array<any> = cyLyList.map(
        ({ month, current_year, last_year }: ICyLyRow) => [
          month,
          current_year,
          last_year,
        ]
      );
      data.unshift([Strings.chart.month, Strings.chart.cy, Strings.chart.ly]);
      return data;
    } else
      return [
        [Strings.chart.month, Strings.chart.cy, Strings.chart.ly],
        [Strings.chart.noData, 0, 0],
      ];
  }

  getInventoryChartData() {
    const { inventoryList } = this.state;
    const { currentMonthIndex, monthList } = this.state;

    let data =
      inventoryList
        .filter(
          ({ month }) =>
            String(month)
              .toLowerCase()
              .localeCompare(monthList[currentMonthIndex]) === 0
        )
        .map((item: any) => item.data ?? [])
        .map((array: any) =>
          array.map((item: any) => [item.category, item.percentage])
        )[0] ?? [];

    data.unshift([Strings.chart.category, Strings.chart.amount]);
    return data;
  }

  getSalesByDistributionChartData() {
    const { salesByDistributionList, monthList, currentMonthIndex } =
      this.state;

    if (salesByDistributionList.length > 0) {
      let items: Array<any> = salesByDistributionList.filter(
        (item: ISalesByDistributionRow) =>
          String(item.month).toLowerCase() === monthList[currentMonthIndex]
      );

      if (items.length > 0) {
        const {
          card_payment,
          sadad_payment,
          cash_on_delivery,
        }: ISalesByDistributionRow = items[0];

        return [
          [Strings.chart.type, Strings.chart.count],
          [Strings.chart.saddadPayment, sadad_payment],
          [Strings.chart.cardPayment, card_payment],
          [Strings.chart.cod, cash_on_delivery],
        ];
      } else return [[Strings.chart.type, Strings.chart.count]];
    } else return [[Strings.chart.type, Strings.chart.count]];
  }

  onChangeMonth(flag: boolean) {
    let { currentMonthIndex, monthList } = this.state;
    let condition1 = currentMonthIndex >= monthList.length - 1 && flag;
    let condition2 = currentMonthIndex <= 0 && !flag;

    if (condition1 || condition2) return;

    this.setState({
      currentMonthIndex: flag ? currentMonthIndex + 1 : currentMonthIndex - 1,
    });
  }
}

export default AdHocReportingController;

// Customizable Area End
