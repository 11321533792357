Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports.getMethodType = "GET";
exports.postApiMethodType = "POST";
exports.putApiMethodType = "PUT";
exports.applicationJsonMethodType="application/json"
exports.getCatelogApiUrl="bx_block_catalogue/catalogues/list_own_catalogues"
exports.createSubscriptionApiCallId="bx_block_custom_user_subs/subscriptions"

exports.Strings = {
  header: {
    titleAdd: "JHC | Add Subscription",
    titleEdit: "JHC | Edit Subscription",
    headingAdd: "Add Subscription Plan",
    headingEdit: "Edit Subscription Plan",
  },
  table: {
    heading: "Subscription Plan Details",
    name: "Product",
    description: "Description",
    duration: "Duration",
    price: "Price",
    freeTrial: "Free Trial",
    renewal: "Renewal",
    status: "Status",
    userRole: "User Role",
  },
  actions: {
    save: "Save",
    saveChanges: "Save Changes",
    createLoadingText:"Saving..",
    updateLoadingText:"Updating.."
  },
};
