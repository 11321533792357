Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";

exports.refundOrderItemsApi =
  "/bx_block_order_management/orders/refund_partial_order";
exports.refundOrderApi = "/bx_block_order_management/orders/refund_order";
exports.refundPartialOrderApi = "/bx_block_order_management/orders/refund_partial_order";
exports.reasonListAPi = "/bx_block_order_management/orders/refund_issues";
exports.ordersApiContentType = "application/json";
exports.ordersAPiEndPoint = "bx_block_order_management/orders/my_orders";
exports.rateAPiEndPoint = "catalogue/reviews";
exports.orderdetailsAPIURL = "bx_block_order_management/orders";
exports.frequantlyRepurchasedAPIURL =
  "/bx_block_catalogue/catalogues/deals_on_frequently_purchased_item?page=1&per_page=10";
// Customizable Area End
