// Customizable Area Start
import React from "react";
import { Link } from "react-router-dom";
import CheckoutController, {
  Props,
  configJSON
} from "./CheckoutController";

export default class Checkout extends CheckoutController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { showAddAddressPopup, checkoutInputValues, checkoutValidation, addressItems, checkoutItemsData, addAddressMessage, showDivs, instruction, defaultInstructionValidation, defaultInstructionMessage, useThisAddressButton, isPaymentButtonDisabled, countries, states, cities, showThanksPopup, showInstructionPopup, disableButton} = this.state;
    return (<>
     
      <div className='shoppingCart'>
        <div className='title'>Checkout</div>
        <div className='flex-box'>
          <div className='flex-column1'>
            <div id="cartAccordion">
              <div className="card">
                {/* @ts-ignore */}
                <div className="card-header" data-bs-toggle="collapse" onClick={() => {this.showAddressBox()}}>
                  <h5>Select a delivery address</h5>
                </div>
                <div id="cartOne" className={showDivs.first ? "collapse show" : "collapse"} data-bs-parent="#cartAccordion">
                  <div className="card-body">
                    <h6 className='common-heading'>Recently Used</h6>
                    {addressItems?.length > 0 && addressItems?.map((result: any, i: number) =>
                    (
                        <div className='flex-box p-0 mb-3' key={'AddressList' + i}>
                          <div className='address-input'><input type='radio' id='address1' onChange={() => this.selectDefaultAddress(i)} name='default_address' checked={result?.attributes?.is_default} /></div>
                          <div className='address-label'>
                            <p className='mb-2'><label className='addressLabel' htmlFor='address1'>
                              <strong>{result?.attributes?.name},</strong> {result?.attributes?.flat_no}, {result?.attributes?.address}, {result?.attributes?.address_line_2}, {result?.attributes?.city}, {result?.attributes?.state ? Object.values(result.attributes.state) + ',' : ''} {result?.attributes?.country ? Object.values(result?.attributes?.country) :  ''} <br />Phone Number:  {result?.attributes?.phone_number}
                            </label></p>
                            <span className='blueLinks cursor-pointer' onClick={()=>{this.showAddress(result?.id, i)}}>Edit</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span className='blueLinks cursor-pointer' onClick={() => {this.openAddInstructionPopUP(result?.id, i)}}>{result?.attributes?.instructions ? 'Edit' : 'Add'} Instructions</span>
                          </div>
                        </div>
                    )
                    )}
                    <div>
                    {addressItems?.length > 0 && (<button className='filled-btn' type='button' disabled={useThisAddressButton} onClick={() => {this.showPaymentBox()}}>Use This Address</button>)} &nbsp;&nbsp;&nbsp;&nbsp;
                      <button className='filled-btn' type='button' onClick={()=> {this.openAddAddressPopup()}}>Add New Address</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" data-bs-toggle="collapse">
                  <h5>Payment Method</h5>
                </div>
                <div id="cartTwo" className={showDivs.second ? "collapse show" : "collapse"} data-bs-parent="#cartAccordion">
                  <div className="card-body">
                    <h6 className='common-heading'>Choose Method</h6>
                    <div className='flex-box p-0 mb-3'>
                      <div className='address-input'><input type='radio' id='method1' disabled={true} name='method' /></div>
                      <div className='address-label'>
                        <p className='mb-2'><label className='addressLabel' htmlFor='method1'>
                          <strong>Pay by Card</strong>
                        </label></p>
                        <div className='ms-3'>
                          <div className='row m-0 p-0'>
                            <div className='col-6'><p className='mb-0 ps-4'>Your saved Debit and Credit cards</p></div>
                            <div className='col-3'><p className='mb-0'>Name on Card</p></div>
                            <div className='col-3 text-end'><p className='mb-0'>Expires On</p></div>
                          </div>
                          <div className='row activeCard m-0 p-0'>
                            <div className='col-6'>
                              <input type='radio' id='card1' name='method' />&nbsp;&nbsp;&nbsp;&nbsp;
                              <label className='addressLabel' htmlFor='card1'><strong>HDFC Debit Card</strong> <small><em>ending in 5678</em></small> &nbsp; <img src='/frontend/rupay.png' alt='cards' /></label>
                              <div className='card-detail'>
                                <p className='my-2 ms-4'>Enter CVV (?) : <input className='p-1' type='text' style={{ width: '100px' }} /> </p>
                                <p className='my-2'><img src='/frontend/i.png' alt='cards' style={{ height: '15px' }} /> This card is recomended for you. Why?</p>
                                <p className='my-2'><input type='checkbox' name='address' /> Save card as per new RBI Guidlines. Learn More</p>
                              </div>
                            </div>
                            <div className='col-3'><label className='addressLabel'><strong>Kategreen</strong></label></div>
                            <div className='col-3 text-end'><label className='addressLabel'><strong>12/2023</strong></label></div>
                          </div>
                          <div className='row m-0 p-0'>
                            <div className='col-6'>
                              <input type='radio' id='card2' name='method' />&nbsp;&nbsp;&nbsp;&nbsp;<label className='addressLabel' htmlFor='card2'><strong>Visa Card</strong> <small><em>ending in 5678</em></small> &nbsp; <img src='/frontend/visa.png' alt='cards' /></label>
                            </div>
                            <div className='col-3'><label className='addressLabel'><strong>Kategreen</strong></label></div>
                            <div className='col-3 text-end'><label className='addressLabel'><strong>12/2023</strong></label></div>
                          </div>
                          <div className='row m-0 p-0'>
                            <div className='col-6'><input type='radio' id='card3' name='method' />&nbsp;&nbsp;&nbsp;&nbsp;<label className='addressLabel' htmlFor='card3'><strong>HDFC Credit Card</strong> <small><em>ending in 5678</em></small> &nbsp; <img src='/frontend/visa.png' alt='cards' /></label></div>
                            <div className='col-3'><label className='addressLabel'><strong>Kategreen</strong></label></div>
                            <div className='col-3 text-end'><label className='addressLabel'><strong>12/2023</strong></label></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className='flex-box p-0 mb-3'>
                      <div className='address-input'><input type='radio' id='method2' name='address' onChange={()=>this.changePaymentMethod()} /></div>
                      <div className='address-label'>
                        <p className='mb-2'><label className='addressLabel' htmlFor='method2'>
                          <strong>Pay by Cash</strong>
                        </label></p>
                        <p className='ms-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                      </div>
                    </div>
                    <hr />
                    <div className='flex-box p-0 mb-3'>
                      <div className='address-input'><input type='radio' disabled={true} id='method3' name='address' /></div>
                      <div className='address-label'>
                        <p className='mb-2'><label className='addressLabel' htmlFor='method3'>
                          <strong>Pay by SADAD</strong>
                        </label></p>
                        <div className='ms-3'>
                          <p className='mb-2'>Enter Online Payment ID Alias: *</p>
                          <input className='p-2' type='text' style={{ width: '100%', maxWidth: '400px' }} />
                        </div>
                      </div>
                      <img style={{ maxWidth: '120px' }} className='float-end' src='/frontend/sadad.png' alt='pay' />
                    </div>
                    <hr />
                    <div onClick={() => {this.makePayment()}} >
                      <button className='filled-btn' type='button' disabled={isPaymentButtonDisabled}>Use this payment method</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" data-bs-toggle="collapse">
                  <h5>Items and Delivery</h5>
                </div>
                <div id="cartThree" className={showDivs.third ? "collapse show" : "collapse"} data-bs-parent="#cartAccordion">
                  <div className="card-body">

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='flex-column2 pt-0'>
            <div className='orderdetail'>
              <div className='head'>Order Summary</div>
              <div className='body'>
                <table className="table table-borderless mb-0">
                  <tbody>
                    <tr>
                      <th className="py-3">Items</th>
                      <td className="py-3">{checkoutItemsData[0]?.attributes?.sub_total} AED</td>
                    </tr>
                    <tr>
                      <th className="py-3">Delivery</th>
                      <td className="py-3">0 AED</td>
                    </tr>
                    <tr className="border-top border-bottom">
                      <th className="py-3">Order Total</th>
                      <td className="py-3">{checkoutItemsData[0]?.attributes?.total} AED</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='body'>
                <br />
                <br />
                <br />
                <p className='mb-0'>How are Delivery cost calculated?</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {showThanksPopup ? <div className="modal show d-block" id="makePayment">
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Thanks for your order</h5>
            </div>
            <div className="modal-body">
              <div className='message'>
                    <h5 className='text-center mb-4'>Your order has been successfully placed.</h5>
                    <div className='buttonBox'>
                      <Link to ={'/orders'} className='buttons filled' onClick={()=>{this.setState({showThanksPopup: false})}}>My Orders</Link>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div> : ''}
      {showInstructionPopup ? (<div className="modal show d-block" id="addInstructions">
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Delivery Instructions</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
            {defaultInstructionMessage && (<p className='formSuccess'>{defaultInstructionMessage}</p>)}
              <div className='row'>
                <div className='col-8'>
                  <div className='boxes p-0 border-0'>
                    <textarea className='w-100' rows={5} name="message" onChange={(e)=>{this.handleInstructionChange(e)}} value={instruction.message} />
                    {defaultInstructionValidation.message && (<p className='formErrors'>{defaultInstructionValidation.message}</p>)}
                  </div>
                </div>
                <div className='col-4'>
                  <div className='timing-box'>
                    <div className='d-flex justify-content-around align-items-center mb-2'>
                      <h5 className='big-font w-50 mb-0'>Saturday</h5> <div className='w-50'><button className={!instruction.isSaturday? 'checkbox-btn active' : 'checkbox-btn'} onClick={() => {this.instructionDetails('saturday', false)}}>No</button><button className={instruction.isSaturday? 'checkbox-btn active' : 'checkbox-btn'} onClick={() => {this.instructionDetails('saturday', true)}}>Yes</button></div>
                    </div>
                    <div className='d-flex justify-content-around align-items-center'>
                      <h5 className='big-font w-50 mb-0'>Sunday</h5> <div className='w-50'><button className={!instruction.isSunday? 'checkbox-btn active' : 'checkbox-btn'} onClick={() => {this.instructionDetails('sunday', false)}}>No</button><button className={instruction.isSunday? 'checkbox-btn active' : 'checkbox-btn'}  onClick={() => {this.instructionDetails('sunday', true)}}>Yes</button></div>
                    </div>
                  </div>
                </div>
              </div>
              <p className='big-font'><em>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</em></p>
              <button className='filled-btn' type='button' disabled={disableButton} onClick={() => {this.saveInstructions()}}>Save Instructions</button>
            </div>
          </div>
        </div>
      </div>) : ''}
      {showAddAddressPopup ? <div className="modal show d-block" id="addNewAddress">
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Enter a new Delivery Address</h5>
              <button type="button" className="btn-close" onClick={()=>{this.setState({showAddAddressPopup: !this.state.showAddAddressPopup})}}>&times;</button>
            </div>
            <div className="modal-body">
              <h5>Add a new address</h5>
              {addAddressMessage && (<p className='formSuccess'>{addAddressMessage}</p>)}
              <form>
                <div className='autofilled'>
                  <h5>Save time. Autofill your current location</h5> <button className='filled-btn px-5' type='button'>Autofill</button>
                </div>
                <select className='modal-inputs' name='checkoutCountry' value={checkoutInputValues.checkoutCountry} onChange={(e) => this.handleCheckoutChange(e, 'country')}>
                  <option value={''}>Country/Region</option>
                  {countries && Object.keys(countries).map(function(key, i) { 
                      return (<option value={key} key={'country' + i}>{countries[key]}</option>)
                    })}
                </select>
                {checkoutValidation.checkoutCountry && <p className='formErrors'>{checkoutValidation.checkoutCountry}</p>}
                <select className='modal-inputs' name='checkoutState' value={checkoutInputValues.checkoutState} onChange={(e) => this.handleCheckoutChange(e, 'state')}>
                  <option value={''}>State</option>
                  {states && Object.keys(states).map(function(key, i) {
                      return (<option value={key} key={'states' + i}>{states[key]}</option>)
                  })}
                </select>
                {checkoutValidation.checkoutState && <p className='formErrors'>{checkoutValidation.checkoutState}</p>}
                <select className='modal-inputs' name='checkoutTown' value={checkoutInputValues.checkoutTown} onChange={(e) => this.handleCheckoutChange(e)}>
                  <option value={''}>City</option>
                  {cities && Object.keys(cities).map(function(key, i) {
                      return (<option value={cities[key]} key={'cities' + i}>{cities[key]}</option>)
                  })}
                </select>
                {checkoutValidation.checkoutTown && <p className='formErrors'>{checkoutValidation.checkoutTown}</p>}
                <input className='modal-inputs' name='checkoutFname' value={checkoutInputValues.checkoutFname} onChange={(e) => this.handleCheckoutChange(e)} placeholder='Full Name' />
                {checkoutValidation.checkoutFname && <p className='formErrors'>{checkoutValidation.checkoutFname}</p>}
                <input className='modal-inputs' name='checkoutMobile' value={checkoutInputValues.checkoutMobile} onChange={(e) => this.handleCheckoutChange(e)} placeholder='Mobile Number' />
                {checkoutValidation.checkoutMobile && <p className='formErrors'>{checkoutValidation.checkoutMobile}</p>}
                <input className='modal-inputs' name='checkoutZipcode' value={checkoutInputValues.checkoutZipcode} onChange={(e) => this.handleCheckoutChange(e)} placeholder='Zipcode' />
                {checkoutValidation.checkoutZipcode && <p className='formErrors'>{checkoutValidation.checkoutZipcode}</p>}
                <input className='modal-inputs' name='checkoutBuilding' value={checkoutInputValues.checkoutBuilding} onChange={(e) => this.handleCheckoutChange(e)} placeholder='Flat, apartment, building' />
                {checkoutValidation.checkoutBuilding && <p className='formErrors'>{checkoutValidation.checkoutBuilding}</p>}
                <input className='modal-inputs' name='checkoutStreet' value={checkoutInputValues.checkoutStreet} onChange={(e) => this.handleCheckoutChange(e)} placeholder='Area, street, sector, village' />
                {checkoutValidation.checkoutStreet && <p className='formErrors'>{checkoutValidation.checkoutStreet}</p>}
                <input className='modal-inputs' name='checkoutLandmark' value={checkoutInputValues.checkoutLandmark} onChange={(e) => this.handleCheckoutChange(e)} placeholder='Landmark' />
                {checkoutValidation.checkoutLandmark && <p className='formErrors'>{checkoutValidation.checkoutLandmark}</p>}             
                <label><input type='checkbox' name='isDefault' checked={checkoutInputValues.isDefault === 'yes'} value={checkoutInputValues.isDefault} onChange={(e)=>{this.handleCheckboxChange(e)}}/>&nbsp;&nbsp; Make this my default address</label>
                <h5 className='mt-3 mb-1'>Add Delivery Instructions</h5>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                <input className='modal-inputs' name='checkoutAddressType' value={checkoutInputValues.checkoutAddressType} onChange={(e) => this.handleCheckoutChange(e)} placeholder='Address Type' />
                {checkoutValidation.checkoutAddressType && <p className='formErrors'>{checkoutValidation.checkoutAddressType}</p>}
                <button className='filled-btn' type='submit' disabled={disableButton} onClick={(e) => this.saveAddress(e)}>Use This Address</button>
              </form>
            </div>
          </div>
        </div>
      </div> : '' }
    </>)
  }
}
// Customizable Area End
