import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";

import phone from "phone";

import BlockHelpers from "../../../utilities/src/BlockHelpers";
import GetApiMessage from "../../../utilities/src/GetApiMessage";
import GetApiErrorsWithKeys from "../../../vendor/src/GetApiErrorsWithKeys";

export const configJs = require("./config.js");
const ApiUrls = configJs.ApiUrls;
const Strings = configJs.Strings;
const MappedErrorKeys = configJs.MappedErrorKeys;

interface IData {
  name: string;
  phone_number: string;
  address_type: string;
  flat_no: string;
  address: string;
  address_line_2: string;
  landmark: string;
  city: string;
  zip_code: string;
  state: string;
  country: string;
  is_default?: boolean;
}

interface Props {
  onClose: Function;
  onChange: Function;
  selectedAddressId: number;
}

interface State {
  isSunday: boolean;
  isSaturday: boolean;
  instructions: string;
  instructionsError: string;

  isEdit: boolean;
  isAddNewAddress: boolean;
  selectErrorMessage: string;
  isAddNewInstruction: boolean;
  selectedAddress: number | any;

  addressList: Array<any>;
  countryList: Array<any>;
  stateList: Array<any>;
  cityList: Array<any>;

  isLoading: boolean;
  editMessageId: string;
  submitMessageId: string;
  addressMessageId: string;
  cityListMessageId: string;
  stateListMessageId: string;
  countryListMessageId: string;
  instructionsMessageId: string;

  item: IData;
  validation: IData;
}

interface SS {}

class SelectPickUpAddressController extends BlockComponent<Props, State, SS> {
  state = {
    isSunday: false,
    instructions: "",
    isSaturday: false,
    instructionsError: "",

    isEdit: false,
    isLoading: false,
    isAddNewAddress: false,
    selectErrorMessage: "",
    isAddNewInstruction: false,

    cityList: [],
    stateList: [],
    addressList: [],
    countryList: [],

    editMessageId: "",
    submitMessageId: "",
    addressMessageId: "",
    cityListMessageId: "",
    stateListMessageId: "",
    countryListMessageId: "",
    instructionsMessageId: "",

    selectedAddress: null,

    item: {
      city: "",
      name: "",
      state: "",
      country: "",
      flat_no: "",
      address: "",
      landmark: "",
      zip_code: "",
      phone_number: "",
      address_type: "",
      address_line_2: "",
      is_default: false,
    },
    validation: {
      city: "",
      name: "",
      state: "",
      country: "",
      flat_no: "",
      address: "",
      landmark: "",
      zip_code: "",
      address_type: "",
      phone_number: "",
      address_line_2: "",
    },
  };

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
  }

  receive(from: string, message: Message): void {
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const getArrayFromKeys = (item: any) => {
      return Object.keys(item).map((key) => {
        return { label: item[key], value: key };
      });
    };

    const {
      submitMessageId,
      addressMessageId,
      cityListMessageId,
      stateListMessageId,
      countryListMessageId,
      instructionsMessageId,
    } = this.state;

    if (successResponse) {
      switch (requestId) {
        case addressMessageId:
          this.setState({
            addressList: successResponse.data.map(
              (item: any) => item.attributes
            ),
          });
          break;

        case submitMessageId:
          let errors = GetApiErrorsWithKeys(successResponse, MappedErrorKeys);
          if (errors) {
            // @ts-ignore
            this.setState({ validation: errors });
            window.alert(errors.extraMessages);
            BlockHelpers.showAlert(Strings.labels.alert, errors.extraMessages);
          } else {
            this.getAddressList();
            this.setState({ isAddNewAddress: false });
          }
          this.setState({ isLoading: false });
          break;

        case countryListMessageId:
          this.setState({
            countryList: getArrayFromKeys(successResponse.countries),
          });
          break;

        case stateListMessageId:
          this.setState({
            stateList: getArrayFromKeys(successResponse.states),
          });
          break;

        case cityListMessageId:
          this.setState({
            cityList: getArrayFromKeys(successResponse.cities),
          });
          break;

        case instructionsMessageId:
          errors = GetApiErrorsWithKeys(successResponse, {});
          if (errors) {
            // @ts-ignore
            this.setState({ validation: errors });
            window.alert(errors.extraMessages);
            BlockHelpers.showAlert(Strings.labels.alert, errors.extraMessages);
          } else {
            this.setState({
              isAddNewInstruction: false,
              isAddNewAddress: false,
            });
            this.getAddressList();
          }
          break;
      }
    }
  }

  componentDidMount(): any {
    this.getAddressList();
    this.getCountryList();
    let id = this.props.selectedAddressId;
    this.setState({ selectedAddress: id });
  }

  getAddressList() {
    let requestMessage = GetApiMessage({ endpoint: ApiUrls.getAddressList });
    this.setState({ addressMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCountryList() {
    let requestMessage = GetApiMessage({ endpoint: ApiUrls.countryList });
    this.setState({ countryListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStateList(country: string = this.state.item.country) {
    let requestMessage = GetApiMessage({
      endpoint: ApiUrls.stateList + country,
    });
    this.setState({ stateListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCityList(state: string = this.state.item.state) {
    let country = this.state.item.country;
    let postfix = `state=${state}&country=${country}`;
    let requestMessage = GetApiMessage({
      endpoint: ApiUrls.cityList + postfix,
    });
    this.setState({ cityListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onChangeItem(key: string, value: string) {
    this.setState({
      item: {
        ...this.state.item,
        [key]: value,
      },
    });
  }

  isValid() {
    let errors = {};
    // @ts-ignore
    const saveErrors = (key: string, value: string) => (errors[key] = value);

    const {
      address_line_2,
      city,
      country,
      flat_no,
      landmark,
      name,
      phone_number,
      state,
      zip_code,
    } = this.state.item;

    if (!name) saveErrors("name", Strings.validation.name);
    if (!city) saveErrors("city", Strings.validation.city);
    if (!state) saveErrors("state", Strings.validation.state);
    if (!flat_no) saveErrors("flat_no", Strings.validation.flatNo);
    if (!country) saveErrors("country", Strings.validation.country);
    if (!zip_code) saveErrors("zip_code", Strings.validation.zipCode);
    if (!landmark) saveErrors("landmark", Strings.validation.landmark);
    if (!address_line_2)
      saveErrors("address_line_2", Strings.validation.addressLine2);
    if (!phone_number) saveErrors("phone_number", Strings.validation.phone);
    else if (!phone(phone_number, { country: "ARE" }).isValid) {
      saveErrors("phone_number", Strings.validation.validPhone);
    }

    // @ts-ignore
    this.setState({ validation: errors });
    return Object.keys(errors).length === 0;
  }

  onSubmit() {
    // @ts-ignore
    const selectedId = this.state.selectedAddress;
    const isEdit = this.state.isEdit ? true : false;

    if (this.state.isLoading) return;
    if (!this.isValid()) return;

    const { item } = this.state;

    let requestMessage = GetApiMessage({
      method: isEdit ? "put" : "post",
      extraHeaders: {
        "Content-Type": "application/json",
      },
      endpoint: !isEdit
        ? ApiUrls.saveAddress
        : ApiUrls.editAddress + selectedId,
      data: JSON.stringify({
        address: {
          name: item.name,
          phone_number: item.phone_number,
          address_type: item.address_type,
          flat_no: item.flat_no,
          address: item.address || item.flat_no,
          address_line_2: item.address_line_2,
          landmark: item.landmark,
          city: item.city,
          zip_code: item.zip_code,
          state: item.state,
          country: item.country,
          is_default: item.is_default,
        },
      }),
    });

    this.setState({
      submitMessageId: requestMessage.messageId,
    });

    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onMakeDefaultAddress() {
    let isDefault = this.state.item.is_default ? true : false;
    this.setState({
      item: {
        ...this.state.item,
        is_default: !isDefault,
      },
    });
  }

  onClickUseAddress() {
    let { selectedAddress, addressList } = this.state;
    if (selectedAddress) {
      let array: Array<any> = addressList.filter(
        (item: any) => item.id == selectedAddress
      );
      if (array.length > 0) {
        let item = array[0];
        let address = `${item.name}, ${item.flat_no}, ${item.address}, ${
          item.address_line_2
        }, ${item.city}, ${item.state ? item.state + "," : ""} ${
          item.country ? item.country : ""
        }`;

        this.props.onChange({ id: item.id, address });
        this.props.onClose();
      }
    } else {
      this.setState({ selectErrorMessage: Strings.validation.address });
    }
  }

  onPrepareEdit(id: number) {
    this.onCancelForm();

    let item: IData = this.state.addressList.filter(
      (item: any) => item.id === id
    )[0];

    this.getCityList(item.state);
    this.getStateList(item.country);

    setTimeout(
      () =>
        this.setState({
          item,
          // @ts-ignore
          validation: {},
          isEdit: true,
          isAddNewInstruction: false,
          isAddNewAddress: true,
          selectedAddress: id,
        }),
      500
    );
  }

  onClickInstructions(item: any) {
    let isEdit = item.instructions?.length > 0 ? true : false;

    // @ts-ignore
    this.setState({ validation: {}, instructionsError: "" });

    if (isEdit)
      this.setState({
        isEdit,
        isAddNewAddress: false,
        instructions: item.instructions,
        isSaturday: item.on_saturday,
        isSunday: item.on_sunday,
      });
    else
      this.setState({
        isEdit,
        isAddNewAddress: false,
        instructions: "",
        isSaturday: false,
        isSunday: false,
      });

    this.setState({
      isAddNewInstruction: true,
      selectedAddress: item.id,
    });
  }

  onSubmitInstructions() {
    const { instructions, isSaturday, isSunday, selectedAddress } = this.state;
    if (!instructions) {
      this.setState({ instructionsError: Strings.validation.instructionError });
      return;
    }
    let data = JSON.stringify({
      add_instruction: {
        instructions: instructions,
        on_saturday: isSaturday,
        on_sunday: isSunday,
      },
    });

    let requestMessage = GetApiMessage({
      method: "put",
      extraHeaders: {
        "Content-Type": "application/json",
      },
      endpoint: ApiUrls.editAddress + String(selectedAddress),
      data,
    });
    this.setState({ instructionsMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onCancelForm() {
    this.setState({
      isEdit: false,
      isAddNewAddress: false,
      isAddNewInstruction: false,
    });
  }
}

export default SelectPickUpAddressController;
