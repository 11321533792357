import React from "react";
import { styled } from "@material-ui/core";
import { iconArrowLeft, iconArrowRight } from "../assets";

interface Props {
  onNext: Function;
  onPrevious: Function;
  currentMonth: string;
}

const MonthSelector = (props: Props) => {
  const { onNext, onPrevious, currentMonth } = props;
  return (
    <Container>
      <button data-test-id="btn-back" onClick={() => onPrevious()}>
        <img src={iconArrowLeft} />
      </button>
      <div>{currentMonth}</div>
      <button data-test-id="btn-next" onClick={() => onNext()}>
        <img src={iconArrowRight} />
      </button>
    </Container>
  );
};

const Container = styled("div")({
  gap: 12,
  display: "flex",
  borderRadius: 8,
  padding: "4px 12px",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid var(--navy)",
  "& > div": {
    flexGrow: 1,
    textTransform: "capitalize",
  },
  "& > button": {
    width: 12,
    border: 0,
    padding: 0,
    backgroundColor: "white",
  },
});

export default MonthSelector;
