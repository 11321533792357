// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";

import queryString from "querystring";
import GetApiMessage from "../../../utilities/src/GetApiMessage";
import GetApiErrorsWithKeys from "../../../vendor/src/GetApiErrorsWithKeys";

export const configJs = require("./config");
const ApiUrls = configJs.ApiUrls;
const Strings = configJs.Strings;

export interface Props {
  navigation: any;
  id: string;
}

export interface IData {
  id: number;
  email: string;
  password: string;
  last_name: string;
  first_name: string;
  confirmPassword: string;
}

interface S {
  item: IData;
  token: string;
  loading: boolean;
  validation: IData;
  showPassword: boolean;
  memberErrorMessage: any;
  showMemberSignUp: boolean;
  memberSignUpMessageId: any;
  showConfirmPassword: boolean;
  checkMemberLinkMessageId: any;
}

interface SS {}

class AddMembersController extends BlockComponent<Props, S, SS> {
  state = {
    token: "",
    loading: false,
    showPassword: false,
    showMemberSignUp: true,
    showConfirmPassword: false,

    validation: {
      id: 0,
      email: "",
      password: "",
      last_name: "",
      first_name: "",
      confirmPassword: "",
    },
    item: {
      id: 0,
      email: "",
      password: "",
      last_name: "",
      first_name: "",
      confirmPassword: "",
    },

    memberErrorMessage: "",

    // Message Ids
    memberSignUpMessageId: "",
    checkMemberLinkMessageId: "",
  };

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const { checkMemberLinkMessageId, memberSignUpMessageId } = this.state;

    let requestID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (successResponse) {
      if (checkMemberLinkMessageId === requestID) {
        if (successResponse.errors) {
          this.setState({ memberErrorMessage: Strings.validation.tokenError });
          return;
        }
        let data = {
          id: successResponse.data.id,
          ...successResponse.data.attributes,
        };
        this.setState({ token: successResponse.vendor_token });
        this.setState({ item: data });
      } else if (memberSignUpMessageId === requestID) {
        let errors = GetApiErrorsWithKeys(successResponse, {});
        if (!errors) {
          this.setState({ showMemberSignUp: false });
        } else {
          this.setState({ memberErrorMessage: errors.extraMessages });
        }
      }
    }
  }

  componentDidMount(): any {
    super.componentDidMount();
    const query: any = queryString.parse(window.location.search);
    let token = query["?token"];
    this.getMemberDetails(token);
  }

  getMemberDetails(token: string) {
    let form = new FormData();
    form.set("token", token);

    let requestMessage = GetApiMessage({
      endpoint: ApiUrls.checkLinkExpiration,
      method: "post",
      data: form,
    });
    this.setState({
      checkMemberLinkMessageId: requestMessage.messageId,
      token,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onSubmit() {
    if (!this.isValid()) return;

    const { id, password, confirmPassword, first_name, last_name } =
      this.state.item;

    let form = new FormData();
    form.set("first_name", first_name);
    form.set("last_name", last_name);
    form.set("password", password);
    form.set("confirm_password", confirmPassword);
    form.set("status", "active");
    form.set("account_registration", "true");

    let requestMessage = GetApiMessage({
      endpoint: ApiUrls.storeMemberSignUp + id,
      method: "put",
      data: form,
      extraHeaders: {
        token: this.state.token,
      },
    });

    this.setState({ memberSignUpMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onChangeValues(key: string, value: string) {
    this.setState({
      item: {
        ...this.state.item,
        [key]: value,
      },
    });
  }

  isValid() {
    if (this.state.memberErrorMessage === "Token is expired!") return false;

    let errors = {};
    const { first_name, last_name, password, confirmPassword } =
      this.state.item;

    // @ts-ignore
    const saveError = (key: string, value: string) => (errors[key] = value);

    const {
      lastNameValid,
      passwordValid,
      firstNameValid,
      lastNameRequire,
      passwordRequire,
      firstNameRequire,
      confirmPasswordMatch,
      confirmPasswordRequire,
    } = Strings.validation;

    const alphabetCond = /^[a-zA-Z ]*$/;
    if (!first_name) {
      saveError("first_name", firstNameRequire);
    } else if (first_name.trim() === "") {
      saveError("first_name", firstNameRequire);
    } else if (!first_name.match(alphabetCond)) {
      saveError("first_name", firstNameValid);
    }

    if (!last_name) {
      saveError("last_name", lastNameRequire);
    } else if (last_name.trim() === "") {
      saveError("last_name", lastNameRequire);
    } else if (!last_name.match(alphabetCond)) {
      saveError("last_name", lastNameValid);
    }

    const passReg =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[.!@#\$%\^&\*])(?=.{6})/;
    if (!password) {
      saveError("password", passwordRequire);
    } else if (!password.match(passReg)) {
      saveError("password", passwordValid);
    }

    if (!confirmPassword) {
      saveError("confirmPassword", confirmPasswordRequire);
    } else if (!confirmPassword.match(passReg)) {
      saveError("confirmPassword", passwordValid);
    } else if (password !== confirmPassword) {
      saveError("confirmPassword", confirmPasswordMatch);
    }

    // @ts-ignore
    this.setState({ validation: errors });

    return Object.keys(errors).length === 0;
  }

  togglePassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  toggleConfirmPassword() {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  }
}

export default AddMembersController;
// Customizable Area End
