// Customizable Area Start
import React  from 'react';
import { Link } from 'react-router-dom';
import BrowsingHistoryController from "./BrowsingHistoryController";
import Arrows from './Arrows.web';
import Carousel from 'react-multi-carousel';

class BrowsingHistory extends BrowsingHistoryController {

  render() {
    const { browsingHistoryList } = this.state;
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 7
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 7
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 4
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2
      }
  };
    return (
      <div className='browsingHistory'>
        {browsingHistoryList?.length > 0 && (<>
          <h6 className='heading'>Your Browsing History</h6>
      <Carousel responsive={responsive} showDots={false} autoPlaySpeed={1000} infinite arrows={false} customButtonGroup={<Arrows next='' previous='' />} renderButtonGroupOutside >
      {browsingHistoryList?.length > 0 && browsingHistoryList?.map((result:any, i:number) =>
        (
          <Link to={'/product/' + result?.id} key={'browsing' + i}><div className='historyCarousel'><img className='historyImages' src={result?.attributes?.images?.length > 0 ? result?.attributes?.images[0]?.url :'/frontend/product1.png'} alt={result?.attributes?.name}/></div></Link>
        )
      )} 
      </Carousel>
        </>)}
  </div>
    )

  }
}


export default BrowsingHistory;
// Customizable Area End
