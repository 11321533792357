import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import GetApiMessage from "../../utilities/src/GetApiMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  orders: any;
  isCancelVisible: boolean;
  isRateVisible: boolean;
  cancelDialog: boolean;
  starCount: number;
  comment: string;
  token: string;
  itemDetail: any;
  activeOrderId: number;
  activeItemId: number;
  activeAddress: any;
  returnOrderMessageId: any;
  shippedOrderMessageId: any;
  cancelOrderMessageId: any;
  shippedOrders: any;
  cancelOrders: any;
  returnOrders: any;
  showTab: {
    orders: boolean;
    notShipped: boolean;
    cancelled: boolean;
    returned: boolean;
  };
  frequentlyRepurchased: any;
  frequentlyRepurchasedMessageId: any;
  orderPagination: number;
  yetToshipOrderPagination: number;
  cancelledOrderPagination: number;
  returnOrdersPagination: number;
  searchOrderText: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OrdermanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  getOrdersAPICallId: any;
  getItemDetailAPICallId: any;
  cancelOrderAPICallId: any;
  rateOrderAPICallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      orders: {},
      isCancelVisible: false,
      isRateVisible: false,
      cancelDialog: false,
      starCount: 0,
      token: "",
      itemDetail: "",
      activeOrderId: 0,
      activeItemId: 0,
      comment: "",
      activeAddress: [],
      returnOrderMessageId: '',
      shippedOrderMessageId: '',
      cancelOrderMessageId: '',
      shippedOrders: [],
      cancelOrders: [],
      returnOrders: [],
      showTab: {
        orders: true,
        notShipped: false,
        cancelled: false,
        returned: false
      },
      frequentlyRepurchased: [],
      frequentlyRepurchasedMessageId: '',
      orderPagination: 1,
      yetToshipOrderPagination: 1,
      cancelledOrderPagination: 1,
      returnOrdersPagination: 1,
      searchOrderText: ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    if(this.props.navigation.getParam('id')){
      const id  = this.props.navigation.getParam('id');
      this.getItemDetailDataRequest(id);
    }
    this.getFrequentlyRepurchased();
    // Customizable Area End
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Token", message);
      this.getOrdersDataRequest('packed');
      this.getOrdersDataRequest('not_yet_shipped');
      this.getOrdersDataRequest('cancelled');
      this.getOrdersDataRequest('refunded_order')
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      runEngine.debugLog("API Message Recived", JSON.stringify(message));

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getOrdersAPICallId) {
          this.setState({ orders: responseJson });
      }  else if (apiRequestCallId === this.getItemDetailAPICallId) {
        this.setState({itemDetail: responseJson.data});
        this.props.navigation.navigate("OrderDetails", {
          DATA: responseJson.data,
        });
      } else if (apiRequestCallId === this.rateOrderAPICallId) {
        this.setState({ isRateVisible: !this.state.isRateVisible });
        this.props.navigation.goBack();
      } else if (apiRequestCallId === this.cancelOrderAPICallId) {
        this.getOrdersDataRequest('packed', this.state.searchOrderText, 1);
        this.getOrdersDataRequest('not_yet_shipped', this.state.searchOrderText, 1);
        this.getOrdersDataRequest('cancelled', this.state.searchOrderText, 1);
        this.getOrdersDataRequest('refunded_order', this.state.searchOrderText)
        this.setState({
          activeOrderId: 0,
          activeItemId: 0,
          isCancelVisible: false,
          cancelDialog: true,
        });
      } else if (apiRequestCallId === this.state.shippedOrderMessageId) {
        this.setState({shippedOrders: responseJson});
      } else if (apiRequestCallId === this.state.cancelOrderMessageId) {
        this.setState({cancelOrders: responseJson});
      } else if (apiRequestCallId === this.state.frequentlyRepurchasedMessageId) {
        this.setState({frequentlyRepurchased: responseJson.data});
      } else if(apiRequestCallId === this.state.returnOrderMessageId){
        this.setState({ returnOrders: responseJson })
      } else if (errorReponse || responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }
  // Customizable Area Start

  cancelOrder = (orderId: number, itemId: number) => {
    this.setState({
      activeOrderId: orderId,
      activeItemId: itemId,
      isCancelVisible: !this.state.isCancelVisible,
    });
  };
  rateOrder = () => {
    this.setState({ isRateVisible: !this.state.isRateVisible });
  };
  hideCancelModal = () => {
    this.setState({ isCancelVisible: false });
  };
  selectCancel = () => {
    this.setState({ isCancelVisible: false, cancelDialog: true });
  };

  getOrdersDataRequest = (type: string, searchText: any = '', page = 1) => {
    const header = {
      "Content-Type": configJSON.ordersApiContentType,
      token: localStorage.getItem('token'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const search = searchText ? '&key=' + searchText.toString().replace('#', '') : '';
    let url = '';

    if (type === 'packed') {
      this.getOrdersAPICallId = requestMessage.messageId;
      url = configJSON.ordersAPiEndPoint + '?per_page=5&page=' + page + search;
    } else if (type === 'not_yet_shipped') {
      this.setState({ shippedOrderMessageId: requestMessage.messageId });
      url = configJSON.ordersAPiEndPoint + '?status=' + type + '&per_page=5&page=' + page + search;
    } else if (type === 'cancelled') {
      this.setState({ cancelOrderMessageId: requestMessage.messageId });
      url = configJSON.ordersAPiEndPoint + '?status=' + type + '&per_page=5&page=' + page + search;
    } else if (type === 'refunded_order') {
      this.setState({ returnOrderMessageId: requestMessage.messageId });
      url = configJSON.ordersAPiEndPoint + '?status=' + type + '&per_page=5&page=' + page + search;
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getItemDetailDataRequest = (id: number) => {
    const header = {
      "Content-Type": configJSON.ordersApiContentType,
      token: localStorage.getItem('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getItemDetailAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.orderdetailsAPIURL + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  cancelOrderDataRequest = (orderId: number = 0) => {
    const header = {
      "Content-Type": configJSON.ordersApiContentType,
      token: localStorage.getItem('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cancelOrderAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.orderdetailsAPIURL + `/${orderId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'delete'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  rateOrderDataRequest = (id: number) => {
    const header = {
      "Content-Type": configJSON.ordersApiContentType,
      token: this.state.token,
    };
    var data = {
      catalogue_id: id,
      rating: this.state.starCount,
      comment: this.state.comment,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.rateOrderAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.rateAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  openTab(tabNumber: number) {
    if (tabNumber === 1) {
      this.getOrdersDataRequest('packed', '', 1);
      const data = {
        orders: true,
        notShipped: false,
        cancelled: false,
        returned: false
      }
      this.setState({ showTab: data, searchOrderText: '' });
    } else if (tabNumber === 2) {
      this.getOrdersDataRequest('not_yet_shipped', '', 1);
      const data = {
        orders: false,
        notShipped: true,
        cancelled: false,
        returned: false
      }
      this.setState({ showTab: data, searchOrderText: '' });
    } else if (tabNumber === 3) {
      this.getOrdersDataRequest('cancelled', '', 1);
      const data = {
        orders: false,
        notShipped: false,
        cancelled: true,
        returned: false
      }
      this.setState({ showTab: data, searchOrderText: '' });
    } else if (tabNumber === 4) {
      this.getOrdersDataRequest('refunded_order', '', 1);
      const data = {
        orders: false,
        notShipped: false,
        cancelled: false,
        returned: true
      }
      this.setState({ showTab: data, searchOrderText: '' });
    }
  }
  async getFrequentlyRepurchased() {
    const requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.frequantlyRepurchasedAPIURL });
    this.setState({ frequentlyRepurchasedMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  showPaginationData(type: string, paginationType: string) {
    if (type === 'packed') {
      const newPage = (paginationType === 'previous') ? this.state.orderPagination - 1 : this.state.orderPagination + 1;
      this.getOrdersDataRequest('packed', this.state.searchOrderText, newPage);
      this.setState({ orderPagination: newPage });
    } else if (type === 'not_yet_shipped') {
      const newPage = (paginationType === 'previous') ? this.state.yetToshipOrderPagination - 1 : this.state.yetToshipOrderPagination + 1;
      this.getOrdersDataRequest('not_yet_shipped', this.state.searchOrderText, newPage);
      this.setState({ yetToshipOrderPagination: newPage });
    } else if (type === 'cancelled') {
      const newPage = (paginationType === 'previous') ? this.state.cancelledOrderPagination - 1 : this.state.cancelledOrderPagination + 1;
      this.getOrdersDataRequest('cancelled', this.state.searchOrderText, newPage);
      this.setState({ cancelledOrderPagination: newPage });
    }
    else if (type === 'refunded_order') {
      const newPage = (paginationType === 'previous') ? this.state.returnOrdersPagination - 1 : this.state.returnOrdersPagination + 1;
      this.getOrdersDataRequest('refunded_order', this.state.searchOrderText, newPage);
      this.setState({ returnOrdersPagination: newPage });
    }
  }
  handleOrderSearchChange = (e: any) => {
    this.setState({
      searchOrderText: e.target.value,
    });
  };

  searchOrder() {
    const { searchOrderText, showTab } = this.state
    const { cancelled, notShipped, orders, returned } = showTab

    if (searchOrderText) {
      if (orders) {
        this.getOrdersDataRequest('packed', searchOrderText, 1);
      } else if (notShipped) {
        this.getOrdersDataRequest('not_yet_shipped', searchOrderText, 1);
      } else if (cancelled) {
        this.getOrdersDataRequest('cancelled', searchOrderText, 1);
      } else if (returned) {
        this.getOrdersDataRequest('refund_orders', searchOrderText, 1);
      }
    }
  }
  // Customizable Area End
}
