import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
  Image,
} from "react-native";

import {Link} from "react-router-dom";
import CommonCarousel from "../../FrontLayout/src/CommonCarousel.web";
// Customizable Area End

import Moment from "moment";
// import {WebView} from "react-native-webview"
import OrdermanagementController, {
  Props,
  //   configJSON
} from "./OrdermanagementController";
// //@ts-ignore
// import StarRating from "react-native-star-rating";

export default class Ordermanagement extends OrdermanagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
};
  // Customizable Area End

  render() {
    // Customizable Area Start
      const { orders, returnOrders, shippedOrders, cancelOrders, showTab, frequentlyRepurchased, returnOrdersPagination, orderPagination, yetToshipOrderPagination, cancelledOrderPagination, searchOrderText } = this.state;
    // Customizable Area End
      return (
          <div className='frontContent py-4 px-5'>
              <div className='breadcrumbs'>
                  <Link to='/home' className='breadcrumb-link'>Home </Link>&nbsp;&nbsp; &#187; &nbsp;&nbsp;
                  <span className='breadcrumb-text'> My Order Details</span>
              </div>
              <div className='title-box'>
                  <h4 className='title'>My Order Details</h4>
                  <div className='search-box'>
                      <input className='search-input' data-test-id="searchInput" type='search' placeholder='Search All Orders' name="search" value={searchOrderText} onChange={(e) => { this.handleOrderSearchChange(e) }} />
                      <button type='button' className='search-btn'><img src='/frontend/search-navy.png' alt='search' data-test-id="search" onClick={() => { this.searchOrder() }} /></button>
                  </div>
              </div>
              <ul className="nav nav-pills">
                  <li className="nav-item" onClick={() => { this.openTab(1) }}>
                      <a className={showTab.orders === true ? "nav-link active" : "nav-link"} data-bs-toggle="pill" href="#orders">Orders</a>
                  </li>
                  <li className="nav-item" onClick={() => { this.openTab(2) }}>
                      <a className={showTab.notShipped === true ? "nav-link active" : "nav-link"} data-bs-toggle="pill" href="#shipped">Not Yet Shipped</a>
                  </li>
                  <li className="nav-item" onClick={() => { this.openTab(3) }}>
                      <a className={showTab.cancelled === true ? "nav-link active" : "nav-link"} data-bs-toggle="pill" href="#cancelled">Cancelled Order</a>
                  </li>
                  <li className="nav-item" onClick={() => { this.openTab(4) }}>
                      <a className="nav-link" data-bs-toggle="pill" href="#returned">Returned Order</a>
                  </li>
              </ul>

              <div className="tab-content my-3">
                  <div className={showTab.orders === true ? "tab-pane active" : "tab-pane fade"} id="orders">
                      {orders?.message ? (
                          <div className='no-pending-orders'>
                              <h6>No order found.</h6>
                          </div>
                      ) : (
                          <div>
                              <div className='orderNumber'> {orders?.meta?.total} order(s) placed</div>
                              {orders?.data?.length > 0 && orders.data.map((result: any, i: number) => (
                                  <div className='order-flex-box' key={'all-orders-items-' + i}>
                                      <div className='order-flex-column1'>
                                          <Link to={'/product/' + result?.attributes?.order_items[0]?.attributes?.catalogue?.id}><img className='w-100 h-100' src={result?.attributes?.order_items[0]?.attributes?.catalogue?.attributes?.images.length > 0 ? result.attributes.order_items[0].attributes.catalogue.attributes.images[0].url : '/frontend/product1.png'} alt='product' /></Link>
                                      </div>
                                      <div className='order-flex-column2'>
                                          <div className='flex-box'>
                                              <div className='column'>
                                                  <h6 className='m-0'>Order #{result?.attributes?.order_number}</h6>
                                                  <p className='m-0'>Delivered 01-Sept-2022</p>
                                                  <p className='m-0'>{result?.attributes?.order_items.length} Product(s)</p>
                                                  <p className='m-0'>Order Placed : {Moment(result?.attributes?.order_date).format('DD MMMM, YYYY')}</p>
                                                  <p className='m-0'>Total : AED {result?.attributes?.total}</p>
                                              </div>
                                              <div className='column'>
                                                  <h6 className='m-0'>SHIP TO</h6>
                                                  <p className='m-0'>{result?.attributes?.delivery_address}</p>
                                              </div>
                                              <div className='column'>
                                                  <Link to={'/order-details/' + result?.id} className='blueLinks mb-0 text-decoration'>View Order Details</Link>
                                                  <Link to='/track' className='blueLinks mb-0 text-decoration'>Track Package</Link>
                                                  {result?.attributes?.status === 'delivered' ? <Link to={'/return-order/' + result?.id} className='blueLinks mb-0 text-decoration'>Return items</Link>: ''}
                                                  <Link to={'/invoice/' + result?.id} className='blueLinks mb-0 text-decoration'>Invoice</Link>
                                              </div>
                                          </div>
                                          <div className='flex-box'>
                                              <div className='column'>
                                                  <Link to={'/product/' + result?.attributes?.order_items[0]?.attributes?.catalogue?.id}><button className='filled-btn' type='button'>Buy It Again</button></Link>
                                              </div>
                                              {(result?.attributes?.status === 'placed' || result?.attributes?.status === 'confirmed') && (<div className='column'>
                                                  <div className='blueLinks text-decoration mb-0' style={{ cursor: 'pointer' }} data-test-id="orderCancel" onClick={() => { this.cancelOrderDataRequest(result?.id) }}>Cancel Order</div>
                                              </div>)}
                                          </div>
                                      </div>
                                  </div>
                              ))}
                          </div>
                      )}
                      {orders?.meta?.last_page > 1 && (
                          <div className='text-end mt-3'>
                              {orderPagination > 1 && <button type='button' className='border-btn me-2' onClick={() => { this.showPaginationData('packed', 'previous') }}>Previous</button>}
                              {orders.meta.last_page > orderPagination && <button type='button' className='filled-btn' onClick={() => { this.showPaginationData('packed', 'next') }}>Next</button>}
                          </div>
                      )}
                  </div>
                  <div className={showTab.notShipped === true ? "tab-pane active" : "tab-pane fade"} id="shipped">
                      {shippedOrders?.message ? (
                          <div className='no-pending-orders'>
                              <h6>Looking for an order? All of your orders has been dispatched. <div className='blueLinks d-inline text-decoration' data-text-id="shippingShowTab" onClick={() => { this.openTab(1) }}>View all orders.</div></h6>
                          </div>
                      ) : (
                          <div>
                              <div className='orderNumber'> {shippedOrders?.meta?.total} unshipped order(s)</div>
                              {shippedOrders?.data?.length > 0 && shippedOrders.data.map((result: any, i: number) => (
                                  <div key={'shipped-orders-items-' + i} className='order-flex-box mb-3'>
                                      <div className='order-flex-column1'>
                                      <Link to={'/product/' + result?.attributes?.order_items[0]?.attributes?.catalogue?.id}><img className='w-100 h-100' src={result?.attributes?.order_items[0]?.attributes?.catalogue?.attributes?.images.length > 0 ? result.attributes.order_items[0].attributes.catalogue.attributes.images[0].url : '/frontend/product1.png'} alt='product' /></Link>
                                      </div>
                                      <div className='order-flex-column2'>
                                          <div className='flex-box'>
                                              <div className='column'>
                                                  <h6 className='m-0'>Order #{result?.attributes?.order_number}</h6>
                                                  <p className='m-0'>Delivered 01-Sept-2022</p>
                                                  <p className='m-0'>{result?.attributes?.order_items.length} Product(s)</p>
                                                  <p className='m-0'>Order Placed : {Moment(result?.attributes?.order_date).format('DD MMMM, YYYY')}</p>
                                                  <p className='m-0'>Total : AED {result?.attributes?.total}</p>
                                              </div>
                                              <div className='column'>
                                                  <h6 className='m-0'>SHIP TO</h6>
                                                  <p className='m-0'>{result?.attributes?.delivery_address}</p>
                                              </div>
                                              <div className='column'>
                                                  <Link to={'/order-details/' + result?.id} className='blueLinks mb-0 text-decoration'>View Order Details</Link>
                                                  <Link to='/track' className='blueLinks mb-0 text-decoration'>Track Package</Link>
                                                  <Link to='/' className='blueLinks mb-0 text-decoration'>Cancel items</Link>
                                                  <Link to={'/invoice/' + result?.id} className='blueLinks mb-0 text-decoration'>Invoice</Link>
                                              </div>
                                          </div>
                                          <div className='flex-box'>
                                              <div className='column'></div>
                                              {(result?.attributes?.status === 'placed' || result?.attributes?.status === 'confirmed') && (<div className='column'>
                                                  <div className='blueLinks text-decoration mb-0' style={{ cursor: 'pointer' }} data-test-id="orderShippedCancel" onClick={() => { this.cancelOrderDataRequest(result?.id) }}>Cancel Order</div>
                                              </div>)}
                                          </div>
                                      </div>
                                  </div>
                              ))}
                          </div>
                      )}
                      {shippedOrders?.meta?.last_page > 1 && (
                          <div className='text-end mt-3'>
                              {yetToshipOrderPagination > 1 && <button type='button' className='border-btn me-2' onClick={() => { this.showPaginationData('not_yet_shipped', 'previous') }}>Previous</button>}
                              {shippedOrders.meta.last_page > yetToshipOrderPagination && <button type='button' className='filled-btn' onClick={() => { this.showPaginationData('not_yet_shipped', 'next') }}>Next</button>}
                          </div>
                      )}
                      {frequentlyRepurchased.length > 0 && <CommonCarousel name="Deals on frequently repurchased items" items={frequentlyRepurchased} sectionSlug={''} bestSeller={''} resultDeal={''} />}
                  </div>
                  <div className={showTab.cancelled === true ? "tab-pane active" : "tab-pane fade"} id="cancelled">
                      {cancelOrders?.message ? (
                          <div className='no-pending-orders'>
                              <h6>No cancelled order found.<div className='blueLinks d-inline text-decoration' data-test-id="showTab1" onClick={() => { this.openTab(1) }}>View all orders.</div></h6>
                          </div>
                      ) : (
                          <div>
                              <div className='orderNumber'> {cancelOrders?.meta?.total} cancelled order(s)</div>
                              {cancelOrders?.data?.length > 0 && cancelOrders.data.map((result: any, i: number) => (
                                  <div key={'order-items-' + i} className='order-flex-box'>
                                      <div className='order-flex-column1'>
                                      <Link to={'/product/' + result?.attributes?.order_items[0]?.attributes?.catalogue?.id}><img className='w-100 h-100' src={result?.attributes?.order_items[0]?.attributes?.catalogue?.attributes?.images.length > 0 ? result.attributes.order_items[0].attributes.catalogue.attributes.images[0].url : '/frontend/product1.png'} alt='product' /></Link>
                                      </div>
                                      <div className='order-flex-column2'>
                                          <div className='flex-box'>
                                              <div className='column'>
                                                  <h6 className='m-0'>Order #{result?.attributes?.order_number}</h6>
                                                  <p className='m-0'>Cancelled</p>
                                                  <p className='m-0'>{result?.attributes?.order_items.length} Product(s)</p>
                                                  <p className='m-0'>Order Placed : {Moment(result?.attributes?.order_date).format('DD MMMM, YYYY')}</p>
                                                  <p className='m-0'>Total : AED {result?.attributes?.total}</p>
                                              </div>
                                              <div className='column'>
                                                  <h6 className='m-0'>SHIP TO</h6>
                                                  <p className='m-0'>{result?.attributes?.delivery_address}</p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              ))}
                          </div>
                      )}
                      {cancelOrders?.meta?.last_page > 1 && (
                          <div className='text-end mt-3'>
                              {cancelledOrderPagination > 1 && <button type='button' className='border-btn me-2' onClick={() => { this.showPaginationData('cancelled', 'previous') }}>Previous</button>}
                              {cancelOrders.meta.last_page > cancelledOrderPagination && <button type='button' className='filled-btn' onClick={() => { this.showPaginationData('cancelled', 'next') }}>Next</button>}
                          </div>
                      )}
                  </div>
                  <div className={showTab.returned === true ? "tab-pane active" : "tab-pane fade"} id="returned">
                      {returnOrders?.message ? (
                          <div className='no-pending-orders'>
                              <h6>No return order found.<div className='blueLinks d-inline text-decoration' data-test-id="showTab2" onClick={() => { this.openTab(1) }}>View all orders.</div></h6>
                          </div>
                      ) : (
                          <div>
                              <div className='orderNumber'> {returnOrders?.meta?.total} returned order(s)</div>
                                  {returnOrders?.data?.length > 0 && returnOrders.data.map((result: any, i: number) => (
                                      <div className='order-flex-box' key={'all-orders-items-' + i}>
                                          <div className='order-flex-column1'>
                                          <Link to={'/product/' + result?.attributes?.order_items[0]?.attributes?.catalogue?.id}><img className='w-100 h-100' src={result?.attributes?.order_items[0]?.attributes?.catalogue?.attributes?.images.length > 0 ? result.attributes.order_items[0].attributes.catalogue.attributes.images[0].url : '/frontend/product1.png'} alt='product' /></Link>
                                          </div>
                                          <div className='order-flex-column2'>
                                              <div className='flex-box'>
                                                  <div className='column'>
                                                      <h6 className='m-0'>Order #{result?.attributes?.order_number}</h6>
                                                      <p className='m-0'>Delivered 01-Sept-2022</p>
                                                      <p className='m-0'>{result?.attributes?.order_items.length} Product(s)</p>
                                                      <p className='m-0'>Order Placed : {Moment(result?.attributes?.order_date).format('DD MMMM, YYYY')}</p>
                                                      <p className='m-0'>Total : AED {result?.attributes?.total}</p>
                                                  </div>
                                                  <div className='column'>
                                                      <h6 className='m-0'>SHIP TO</h6>
                                                      <p className='m-0'>{result?.attributes?.delivery_address}</p>
                                                  </div>
                                                  <div className='column'>
                                                      <Link to={'/order-details/' + result?.id} className='blueLinks mb-0 text-decoration'>View Order Details</Link>
                                                      <Link to={'/return-order-details/' + result?.id} className='blueLinks mb-0 text-decoration'>Return Summary</Link>
                                                      <Link to={'/invoice/' + result?.id} className='blueLinks mb-0 text-decoration'>Invoice</Link>
                                                      
                                                      <br />
                                                      <span style={{ color: 'green' }}>{result?.attributes?.status}</span>
                                                  </div>
                                              </div>
                                              <div className='flex-box'>
                                                  <div className='column'>
                                                      <Link to={'/product/' + result?.attributes?.order_items[0]?.attributes?.catalogue?.id}><button className='filled-btn' type='button'>Buy It Again</button></Link>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  ))}
                          </div>
                      )}
                      {returnOrders?.meta?.last_page > 1 && (
                          <div className='text-end mt-3'>
                              {returnOrdersPagination > 1 && <button type='button' className='border-btn me-2' onClick={() => this.showPaginationData('refunded_order', 'previous')}>Previous</button>}
                              {returnOrders.meta.last_page > returnOrdersPagination && <button type='button' className='filled-btn' onClick={() => this.showPaginationData('refunded_order', 'next')}>Next</button>}
                          </div>
                      )}
                  </div>
              </div>
          </div>
      );
  }
}
// Customizable Area End
