// Customizable Area Start
import phoneValidator from "phone";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";

import GetApiMessage from "../GetApiMessage";
import ISuccessResponse from "../ISuccessResponse";
import ApiResponseMiddleware from "../ApiResponseMiddleware";
import GetErrorMessages from "../GetErrorMessageFromResponse";
import BlockHelpers from "../../../utilities/src/BlockHelpers";

const configJs = require("./config");
const Strings = configJs.Strings;
const ApiUrls = configJs.ApiUrls;

interface Item {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  reason: null | number | string;
  description: string;
}
interface Props {}
interface State {
  data: any | Item;
  validation: any | Item;
  selectedFaqId: any;
  contactUsList: Array<any>;
  modalFlag: any;
  submitMessageId: any;
  contactUsMessageId: any;
}
interface SS {}

class ContactUsController extends BlockComponent<Props, State, SS> {
  state = {
    data: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      reason: null,
      description: "",
    },
    validation: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      reason: "",
      description: "",
    },

    selectedFaqId: null,
    contactUsList: [],
    modalFlag: false,
    contactUsMessageId: null,
    submitMessageId: null,
  };

  constructor(props: Props) {
    super(props);

    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
    ]);

    this.receive = this.receive.bind(this);
  }

  receive(from: string, message: Message): void {
    if (!ApiResponseMiddleware(message)) return;

    const { contactUsMessageId, submitMessageId } = this.state;

    let requestID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (requestID === contactUsMessageId) {
      if (successResponse) {
        // @ts-ignore
        let list: any = successResponse.data?.map((item: any) => {
          return { id: item.id, ...item.attributes };
        });
        this.setState({ contactUsList: list });
      }
    } else if (requestID === submitMessageId) {
      if (successResponse) {
        let errors = GetErrorMessages(successResponse);
        if (!errors) {
          this.setState({ modalFlag: true });
          this.onReset();
        } else {
          BlockHelpers.showAlert(Strings.labels.alert, errors);
        }
      }
    }
  }

  componentDidMount(): any {
    super.componentDidMount();
    this.getContactUsListApi();
  }

  getContactUsListApi() {
    let requestMessage = GetApiMessage({ endpoint: ApiUrls.getList });
    this.setState({ contactUsMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onClickContinue() {
    const { selectedFaqId } = this.state;

    let form = new FormData();
    form.set("contact_us_query_id", String(selectedFaqId));

    let requestMessage = GetApiMessage({
      method: "post",
      endpoint: ApiUrls.submitContact,
      data: form,
    });

    this.setState({ submitMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  isValid() {
    let errors: any = {};

    const saveErrors = (key: string, value: string) => {
      errors[key] = value;
    };

    const { first_name, last_name, email, phone, description, reason } =
      this.state.data;

    if (!reason) saveErrors("reason", Strings.validation.reason);
    if (!last_name) saveErrors("last_name", Strings.validation.lastName);
    if (!first_name) saveErrors("first_name", Strings.validation.firstName);
    if (!description) saveErrors("description", Strings.validation.description);

    if (!email) saveErrors("email", Strings.validation.email);
    else {
      let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i;
      if (!regex.test(email))
        saveErrors("email", Strings.validation.validEmail);
    }

    if (!phone) saveErrors("phone", Strings.validation.phone);
    else {
      let { isValid } = phoneValidator(phone, { country: "ARE" });
      if (!isValid) saveErrors("phone", Strings.validation.validPhone);
    }

    this.setState({ validation: errors });

    return !(Object.keys(errors).length > 0);
  }

  onSubmit() {
    if (!this.isValid()) return;

    const { first_name, last_name, email, phone, reason, description } =
      this.state.data;

    let form = new FormData();

    form.set("first_name", first_name);
    form.set("last_name", last_name);
    form.set("email", email);
    form.set("phone", phone);
    form.set("contact_us_query_id", String(reason));
    form.set("description", description);

    let requestMessage = GetApiMessage({
      data: form,
      method: "post",
      endpoint: ApiUrls.submitContact,
    });

    this.setState({ submitMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onReset() {
    this.setState({
      data: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        reason: null,
        description: "",
      },
      validation: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        reason: "",
        description: "",
      },
    });
  }

  onChangeItem(key: string, value: string) {
    this.setState({
      data: {
        ...this.state.data,
        [key]: value,
      },
    });
  }
}

export default ContactUsController;
// Customizable Area End
