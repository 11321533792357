// Customizable Area Start

import React from "react";
import moment from "moment";

import PaymentsController from "../PaymentsController";
import NoDataTdDefault from "../../Shared/NoDataTd";

const configJs = require("./config");
const Strings = configJs.Strings;

class OnlinePaymentList extends PaymentsController {
  render() {
    const { onlinePaymentList } = this.state;
    return (
      <>
        <div className="inventory-table mt-2">
          <table className="table">
            <thead>
              <tr>
                <th>{Strings.table.no}</th>
                <th>{Strings.table.date}</th>
                <th>{Strings.table.orderNumber}</th>
                <th>{Strings.table.customerName}</th>
                <th>{Strings.table.paymentMode}</th>
                <th>{Strings.table.billAmount}</th>
                <th>{Strings.table.sku}</th>
                <th>{Strings.table.status}</th>
              </tr>
            </thead>
            <tbody>
              {onlinePaymentList.length === 0 && (
                <tr>
                  <NoDataTdDefault colSpan={8}>
                    {Strings.table.noData}
                  </NoDataTdDefault>
                </tr>
              )}
              {onlinePaymentList.map((item, index) => {
                return (
                  <tr key={"online-payment-list-item" + index}>
                    <td>{index + 1}</td>
                    <td>#123456789</td>
                    <td>brand name</td>
                    <td>batch num</td>
                    <td>{moment().format("DD/MM/YYYY")}</td>
                    <td>{moment().format("DD/MM/YYYY")}</td>
                    <td>43</td>
                    <td>$35.34</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default OnlinePaymentList;

// Customizable Area End
