// Customizable Area Start
import React from "react";
import OffersController from "./OffersController.web";

export default class Offers extends OffersController {
  render() {
    const { offers } = this.state;
    return (
      <div>
        {offers.length > 0 ? (
          <>
            <h6 className="heading">Offers</h6>
            <div className="d-flex flex-wrap justify-content-between m-2">
              {offers?.map((result: any, i: number) => (
                <div className="overlay-offer" key={"offers" + i}>
                  <a href={"/d/offer/" + result.id}><img
                    src={result?.attributes?.photo}
                    alt={result?.attributes?.title}
                    className="w-100"
                  /></a>
                  <div className="top-left">
                    <h5 className="title">{result?.attributes?.title}</h5>
                    <div className="d-flex mb-2">
                      <h4 className="text-start offer-price">
                        Get <br /> Offer
                      </h4>
                      <h4 className="price-rate">{result?.attributes?.offer_percentage}%</h4>
                    </div>
                    <a href="/" className="filled-btn">
                      Get Now
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}
// Customizable Area End
