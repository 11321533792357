// Customizable Area Start
import React from 'react'
import CommonCarousel from '../../FrontLayout/src/CommonCarousel.web'
import Offers from '../../FrontLayout/src/Offers.web'
import OffersPageController, {Props} from './OffersPageController.web'

export default class OffersPage extends OffersPageController {
    constructor(props: Props) {
        super(props);
      }
    
    render() {
        const {
            homeData,
            offersPageList
        } = this.state

        // const offerProps = { isProtected: false, history: { push: () => null } }
        // offerProps.history.push()
        return (
            <div className="HomePage second">
                <div className="frontContent">
                    {/* hero */}
                    <div id="hero" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                      {homeData.length > 0 && Array.from(Array(homeData.length ), (e: any, i: number) => {
                        return (<button type="button" key={'sliderNumber' + i} data-bs-target="#hero" data-bs-slide-to={i} className={(i == 0) ? "active" : ''}></button>)
                      })
                      }
                        
                    </div>                    
                    <div className="carousel-inner">
                    {homeData.length > 0 && homeData.map((result:any, i:number) =>
                          (
                            <div className={(i == 0) ? "carousel-item active" : 'carousel-item'} key={'landing-page-slider' + result.id}>
                            <a href={'/d/offer/' + result.attributes.url}><img src={result.attributes.images ? result.attributes.images : "/frontend/banner.png"} alt="banner" className={'w-100'}/></a>
                        </div>
                  ))}
                    </div>
                </div>
                    {/* content */}
                            {offersPageList?.length > 0 && offersPageList.map((result: any, i: number) => (
                                <CommonCarousel
                                    sectionSlug={'offer/' + result?.attributes?.id}
                                    name={result?.attributes?.title}
                                    items={result?.attributes?.catalogues.data}
                                    bestSeller={''}
                                    resultDeal={''}
                                    key={'offer' + i}
                                />
                            ))}
                    <Offers />
                </div>
            </div>
        )
    }
}

// Customizable Area End
