// Customizable Area Start
import React from "react";
import { styled, Grid, TextField, Button, Typography } from "@material-ui/core";
import ContactUsController from "./ContactUsController";

class ContactUs extends ContactUsController {
  render() {
    return (
      <>
        <Heading>Contact Us</Heading>
        <Container>
          <Grid container spacing={5} justifyContent="center">
            <Grid item xs={12} sm={4}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <StyledInput
                    variant="filled"
                    fullWidth
                    label={"First Name"}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledInput
                    fullWidth
                    variant="filled"
                    label={"Last Name"}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledInput
                    fullWidth
                    variant="filled"
                    label={"Phone"}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledInput
                    fullWidth
                    variant="filled"
                    label={"Email"}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledInput
                    fullWidth
                    variant="filled"
                    label={"Subject"}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledInput
                    fullWidth
                    multiline
                    variant="filled"
                    minRows={4}
                    label={"What do you have in mind ?"}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <StyledButton color="primary" variant="contained">
                        Submit
                      </StyledButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4}>
              <ContactUsTitle>Contact Us</ContactUsTitle>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna al iqua. Ut
                enim
              </p>

              <br />
              <ContactDetails>
                <div>
                  <img src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/ET21.jpg" />
                  <p>+1258 3258 5679</p>
                </div>
                <div>
                  <img src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/ET22.jpg" />
                  <p>hello@workik.com</p>
                </div>
                <div>
                  <img src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/ET23.jpg" />
                  <p>102 street, y cross 485656</p>
                </div>
              </ContactDetails>
              <SocialMediaLinks>
                <a href="">
                  <img src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-mail.svg" />
                </a>
                <a href="">
                  <img src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-twitter.svg" />
                </a>
                <a href="">
                  <img src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-insta.svg" />
                </a>
                <a href="">
                  <img src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-fb.svg" />
                </a>
              </SocialMediaLinks>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}

const Heading: any = styled(Typography)({
  fontSize: 42,
  color: "white",
  marginBottom: 32,
  fontWeight: "bold",
  padding: "42px 0px",
  textAlign: "center",
  textTransform: "uppercase",
  background:
    "linear-gradient(189deg, rgba(0,110,184,1) 0%, rgba(2,101,167,1) 100%)",
});

const SocialMediaLinks = styled("div")({
  gap: 12,
  marginTop: 32,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& img": {
    width: 30,
  },
});

const ContactDetails = styled("div")({
  "& > div": {
    gap: 12,
    display: "flex",
    marginBottom: 16,
    alignItems: "center",
    justifyContent: "flex-start",
    "& > img": {
      width: 40,
    },
    "& > p ": {
      fontSize: 16,
      margin: 0,
    },
  },
});

const ContactUsTitle = styled("h4")({
  marginBottom: 32,
});

const Container = styled("div")({
  padding: "64px 0px",
  textAlign: "center",
});

const StyledButton = styled(Button)({
  width: 120,
  backgroundColor: "var(--navy)",
  "&:hover": {
    color: "white",
    backgroundColor: "var(--navy)",
  },
});

const StyledInput = styled(TextField)({});

export default ContactUs;
// Customizable Area End
