import React from "react";
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CommonCarousel from "../../FrontLayout/src/CommonCarousel.web";
import BrowsingHistory from "../../FrontLayout/src/BrowsingHistory.web";

// Customizable Area End

import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";

export default class ProductDescription extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { placeOrder, productDetail, quantity, addToCartMessage, featured, customerViewed, productRelated, buyItAgain, frequentlyRepurchased, imageUrl, isApproved, addToCartSuccessMessage, isOwnProduct, defaultAddress } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      
      <div className='HomePage'>

        <div className='frontContent p-4'>
          <div className='row m-0 mb-3 p-0'>
            <div className='col-md-5 p-0 mb-3'>
              <div className='product-image'>
                <img className='single-image' src={imageUrl} alt={productDetail?.attributes?.name} />
                <div className='thumbnails'>
                  {productDetail?.attributes?.images?.length > 0 ? (<div className='thumb'>
                    <img src={productDetail?.attributes?.images[0]?.url} data-test-id="mainImage" onClick={() => { this.changeImage(productDetail?.attributes?.images[0]?.url) }} alt='product1' />
                </div>) : ''}
                {productDetail?.attributes?.front_face ? (<div className='thumb'>
                    <img src={productDetail?.attributes?.front_face} data-test-id="frontImage" onClick={() => { this.changeImage(productDetail?.attributes?.front_face) }} alt='product2' />
                  </div>) : ''}
                  {productDetail?.attributes?.back_face ? (<div className='thumb'>
                    <img src={productDetail?.attributes?.back_face} data-test-id="backImage" onClick={() => { this.changeImage(productDetail?.attributes?.back_face) }} alt='product3' />
                  </div>) : ''}
                  {productDetail?.attributes?.top_face ? (<div className='thumb'>
                    <img src={productDetail?.attributes?.top_face} data-test-id="topImage" onClick={() => { this.changeImage(productDetail?.attributes?.top_face) }} alt='product4' />
                  </div>) : ''}
                  {productDetail?.attributes?.bottom_face ? (<div className='thumb'>
                    <img src={productDetail?.attributes?.bottom_face} data-test-id="bottomImage" onClick={() => { this.changeImage(productDetail?.attributes?.bottom_face) }} alt='product5' />
                  </div>) : ''}
                </div>
              </div>
            </div>
            <div className='col-md-7 p-0'>
              <div className='product-detail'>
                <h4 className='product-name'>{productDetail?.attributes?.name}</h4>
                <p className='store-name'>Visit {productDetail?.attributes?.brand?.name}</p>
                <div><img src='/frontend/rating1.png' alt='product' /></div>
                <button className="filled-btn mt-3">Subscribe Now</button>
                <hr />
                <table className="table table-borderless w-auto mb-3">
                  <tbody>
                    <tr>
                      <td><h5 className='p-0 m-0'>MRP</h5></td>
                      <td className='px-2'>:</td>
                      <td><h5 className='p-0 m-0'>{productDetail?.attributes?.retails_price} AED</h5></td>
                    </tr>
                    <tr>
                      <td><h5 className='p-0 m-0'>Price</h5></td>
                      <td className='px-2'>:</td>
                      <td><h5 className='p-0 m-0 redText'>{productDetail?.attributes?.price} AED</h5></td>
                    </tr>
                    <tr>
                      <td><h5 className='p-0 m-0'>You Save</h5></td>
                      <td className='px-2'>:</td>
                      <td><h5 className='p-0 m-0 redText'>{productDetail?.attributes?.you_save}</h5><h5 className='p-0 m-0'>Inclusive or all taxes</h5></td>
                    </tr>
                    <tr>
                      <td><h5 className='p-0 m-0'>Quantity</h5></td>
                      <td className='px-2'>:</td>
                      <td><h5 className='p-0 m-0'><input className='table-input' type='number' value={quantity} onChange={(e) => this.handleQuantityChange(e)} min={1} max={productDetail?.attributes?.stock_qty} /></h5></td>
                      <td colSpan={3}><h5 className='p-0 m-0'>
                        {productDetail?.attributes?.stock_qty > 0 ? (<span className='pe-0 green-text'>In Stock</span>) : (<span className='pe-0 maroon-text'>Out of Stock</span>)}</h5></td>
                    </tr>
                  </tbody>
                </table>
                <p className='mb-0'> Free Delivery <span className='strong'>Tomorrow, Aug 30.</span> Order within <span className='maroon-text'>11 hrs 13 mins.</span> <a href='/' className='blueLinks d-inline' >Detail</a></p>
                {defaultAddress && (<p className='mb-0 blueLinks'><img src='/frontend/location.png' alt='product' /> Deliver to {defaultAddress.flat_no} {defaultAddress?.address} {defaultAddress?.address_line_2} {defaultAddress?.city} {defaultAddress?.state} {defaultAddress?.country} {defaultAddress?.zip_code}</p>)}
                {addToCartMessage && (<p className='formErrors'>{addToCartMessage}</p>)}
                {addToCartSuccessMessage && (<p style={{color: 'green'}}>{addToCartSuccessMessage}</p>)}
                {(productDetail?.attributes?.stock_qty > 0 && isOwnProduct === false) && (<>
                  {isApproved === 'pending' ? <button className="filled-btn mt-3" data-test-id="openPopup" onClick={() => { this.openPopUp(true) }}>Add to Cart</button> : <button className="filled-btn mt-3" data-test-id="addToCart" onClick={() => { this.addToCart() }}>Add to Cart</button>}
                {isApproved === 'pending' ? <button className="border-btn mt-3 ms-2" data-test-id="buyNowOpenPopup" onClick={() => { this.openPopUp(true) }}>Buy Now</button> : <button className="border-btn mt-3 ms-2" data-test-id="buyNow" onClick={() => { this.buyNow() }}>Buy Now</button>}
                </>)}
                <p className='my-3'><img src='/frontend/secure.png' alt='product' /> Secure Transaction</p>
                <div className='offers'>
                  <h5 className='redText mb-0'>{productDetail?.attributes?.offer_description?.title}</h5>
                  <hr className='my-2' />
                  <p dangerouslySetInnerHTML={{ __html: productDetail?.attributes?.offer_description?.description }}></p>
                </div>
                <div className='d-flex flex-wrap w-100 justify-content-between'>
                  <div className='pay-method'><img className='w-100' src='/frontend/cod.png' alt='product' /></div>
                  <div className='pay-method'><img className='w-100' src='/frontend/fastDelivery.png' alt='product' /></div>
                  <div className='pay-method'><img className='w-100' src='/frontend/easyReturn.png' alt='product' /></div>
                  <div className='pay-method'><img className='w-100' src='/frontend/contactless.png' alt='product' /></div>
                  <div className='pay-method'><img className='w-100' src='/frontend/clDelivry.png' alt='product' /></div>
                </div>
              </div>
            </div>
          </div>
          {/* content */}
          <div className='p-4 bg-white'>
            <ul className="nav nav-pills" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-bs-toggle="pill" href="#detail">Product Details</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="pill" href="#description">Product description</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="pill" href="#review">customer review</a>
              </li>
            </ul>
            <div className="tab-content">
              <div id="detail" className="tab-pane active"><br />
                <table className="table table-borderless w-auto mb-4">
                  <tbody>
                    <tr>
                      <th>Brand</th>
                      <td className='px-5'>:</td>
                      <td>{productDetail?.attributes?.brand.name}</td>
                    </tr>
                    <tr>
                      <th>Category</th>
                      <td className='px-5'>:</td>
                      <td>{productDetail?.attributes?.category.name}</td>
                    </tr>
                    <tr>
                      <th>Product Code</th>
                      <td className='px-5'>:</td>
                      <td>{productDetail?.attributes?.product_code}</td>
                    </tr>
                    <tr>
                      <th>Weight</th>
                      <td className='px-5'>:</td>
                      <td>{productDetail?.attributes?.weight}</td>
                    </tr>
                    <tr>
                      <th>Country of Origin</th>
                      <td className='px-5'>:</td>
                      <td>{productDetail?.attributes?.country_of_origin}</td>
                    </tr>
                  </tbody>
                </table>
                <div className='p-4 product-info'>
                  <h5>Product Information</h5>
                  <table className="table table-borderless w-auto mb-4">
                    <tbody>
                      {productDetail?.attributes?.catalogue_variants?.length > 0 && productDetail?.attributes?.catalogue_variants?.map((result: any, i: number) => (
                        <tr key={'product-information' + result.id}>
                          <th>{result?.attributes?.variant_name}</th>
                          <td className='ps-5 pe-3'>:</td>
                          <td>{result?.attributes?.variant_description}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div id="description" className="tab-pane fade"><br />
                <div dangerouslySetInnerHTML={{ __html: productDetail?.attributes?.description }} ></div>
              </div>
              <div id="review" className="tab-pane fade"><br />
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
              </div>
            </div>
          </div>
          <div className='p-4'>
            {featured.length > 0 && (<CommonCarousel name="Featured items you may like" items={featured} sectionSlug={''} bestSeller={''} resultDeal={''} />)}
            {customerViewed.length > 0 && (<CommonCarousel name="Customer who viewed this item also viewed" items={customerViewed} sectionSlug={''} bestSeller={''} resultDeal={''} />)}
            {productRelated.length > 0 && (<CommonCarousel name="Product related to this item" items={productRelated} sectionSlug={''} bestSeller={''} resultDeal={''} />)}
            {buyItAgain.length > 0 && (<CommonCarousel name="Buy it again" items={buyItAgain} sectionSlug={''} bestSeller={''} resultDeal={''} />)}
            {frequentlyRepurchased.length > 0 && (<CommonCarousel name="Deals on frequently repurchased items" items={frequentlyRepurchased} sectionSlug={''} bestSeller={''} resultDeal={''} />)}
          </div>
          <BrowsingHistory />
        </div>
        {placeOrder && (
          <div className="modalBox">
            <div className="modalContent">
              <button type="button" className="btnClose" onClick={() => this.closeModal()}>&times;</button>
              <div className='modalBody'>
                <div className='message'>
                  <h5 className='text-center mb-4'>To place an order, you are required to<br />register and verify your business</h5>
                  <div className='buttonBox'>
                    <button className='buttons filled' type='button' onClick={() => this.closeModal()}>Okay</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      </div>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
























// Customizable Area End
