import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Grid,
  Radio,
  Dialog,
  styled,
  Button,
  DialogContent,
} from "@material-ui/core";

import SelectPickUpAddressController, {
  configJs,
} from "./SelectAddressController";

import AddressForm from "./AddressForm";
import InstructionForm from "./InstructionForm";

const Strings = configJs.Strings;

class SelectPickUpAddress extends SelectPickUpAddressController {
  render() {
    const { onClose } = this.props;
    const {
      isAddNewInstruction,
      selectErrorMessage,
      instructionsError,
      isAddNewAddress,
      selectedAddress,
      instructions,
      countryList,
      addressList,
      isSaturday,
      validation,
      stateList,
      isSunday,
      cityList,
      isEdit,
      item,
    } = this.state;

    return (
      <Dialog
        open={true}
        onClose={() => onClose()}
        data-test-id="select-dialog-modal"
      >
        <StyledDialogContent>
          <DialogTitle onClick={() => onClose()}>
            <div>{Strings.labels.pickupAddress}</div>
            <div style={{ fontSize: 32, cursor: "pointer" }}>&times;</div>
          </DialogTitle>
          <Container>
            {selectErrorMessage && <ErrorText>{selectErrorMessage}</ErrorText>}
            <RecentlyText>{Strings.labels.recentlyUsed}</RecentlyText>
            <div>
              {addressList.map((item: any) => (
                <GridContainer key={"address-item-list-" + item.id}>
                  <div>
                    <Radio
                      // @ts-ignore
                      checked={selectedAddress === item.id}
                      data-test-id={"radio-item-" + item.id}
                      onClick={() =>
                        this.setState({ selectedAddress: item.id })
                      }
                    />
                  </div>
                  <div>
                    <AddressText>
                      <b>{item.name}</b>, {item.flat_no}, {item.address},{" "}
                      {item.address_line_2}, {item.city},{" "}
                      {item.state ? item.state + "," : ""}{" "}
                      {item.country ? item.country : ""}
                    </AddressText>
                  </div>
                  <div />
                  <div>
                    <StyledLink
                      onClick={() => this.onPrepareEdit(item.id)}
                      to={"#"}
                      data-test-id={"address-item-edit-" + item.id}
                    >
                      {Strings.actions.edit}
                    </StyledLink>
                    &nbsp;&nbsp;
                    <StyledLink
                      to={"#"}
                      data-test-id={"address-item-instructions-" + item.id}
                      onClick={() => this.onClickInstructions(item)}
                    >
                      {item.instructions?.length > 0
                        ? Strings.actions.editInstructions
                        : Strings.actions.addInstructions}
                    </StyledLink>
                  </div>
                </GridContainer>
              ))}
              <Box height={12} />
              <GridContainer>
                <div />
                <div>
                  <Grid container spacing={2}>
                    <Grid item>
                      <SubmitButton
                        data-test-id="btn-useAddress"
                        onClick={() => this.onClickUseAddress()}
                      >
                        {Strings.actions.useThisAddress}
                      </SubmitButton>
                    </Grid>

                    {!isAddNewAddress && (
                      <Grid item>
                        <SubmitButton
                          onClick={() =>
                            this.setState({
                              isAddNewInstruction: false,
                              isAddNewAddress: true,
                              isEdit: false,
                              // @ts-ignore
                              validation: {},
                              // @ts-ignore
                              item: {},
                            })
                          }
                        >
                          {Strings.actions.addAddress}
                        </SubmitButton>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </GridContainer>
            </div>
            <br />

            {isAddNewInstruction && (
              <InstructionForm
                isSunday={isSunday}
                isSaturday={isSaturday}
                isEdit={this.state.isEdit}
                instructions={instructions}
                instructionsError={instructionsError}
                onCancelForm={this.onCancelForm.bind(this)}
                onSubmitInstructions={this.onSubmitInstructions.bind(this)}
                setState={(key: string, value: string) =>
                  // @ts-ignore
                  this.setState({ [key]: value })
                }
              />
            )}

            {isAddNewAddress && (
              <AddressForm
                item={item}
                isEdit={isEdit}
                cityList={cityList}
                stateList={stateList}
                validation={validation}
                countryList={countryList}
                onSubmit={this.onSubmit.bind(this)}
                getCityList={this.getCityList.bind(this)}
                onCancelForm={this.onCancelForm.bind(this)}
                getStateList={this.getStateList.bind(this)}
                onChangeItem={this.onChangeItem.bind(this)}
                onMakeDefaultAddress={this.onMakeDefaultAddress.bind(this)}
              />
            )}
          </Container>
        </StyledDialogContent>
      </Dialog>
    );
  }
}

export const StyledInstructionsBox = styled(Box)({
  padding: 16,
  marginTop: -5,
  borderRadius: 4,
  border: "1px solid rgba(0, 0, 0, 0.23)",
});

export const StyledYesNoButton = styled(Button)({
  "&.MuiButton-containedPrimary": {
    color: "white",
    backgroundColor: "var(--navy)",
  },
  "&.MuiButton-outlinedPrimary": {
    color: "var(--navy)",
    border: "1px solid var(--navy)",
  },
});

const ErrorText = styled("div")({
  color: "red",
  paddingBottom: 8,
});

export const MakeThisText = styled("div")({
  fontWeight: 500,
  color: "var(--content)",
});

const AddressText = styled("span")({
  color: "var(--content)",
  fontWeight: 500,
  "& > b": {
    color: "var(--navy)",
    textTransform: "capitalize",
  },
});

const RecentlyText = styled("div")({
  fontWeight: 400,
  marginBottom: 12,
  color: "var(--content)",
});

export const AddNewText = styled("div")({
  fontWeight: 500,
  marginBottom: 12,
  color: "var(--navy)",
});

export const StyledFrom = styled(Box)({
  "& > div": {
    paddingBottom: 16,
  },
});

export const SubmitButton = styled("span")({
  color: "white",
  fontSize: 14,
  fontWeight: 500,
  borderRadius: 4,
  marginBottom: 16,
  cursor: "pointer",
  padding: "8px 16px",
  boxShadow: "0px 0px 2px gray",
  backgroundColor: "var(--navy)",
});

const Container = styled("div")({
  padding: 16,
  paddingBottom: 24,
  width: 600,
  "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    transform: "translate(14px, -10px) scale(0.75)",
    backgroundColor: "white",
    padding: "0px 4px",
  },
  "& .MuiRadio-colorSecondary.Mui-checked, & .MuiCheckbox-colorSecondary.Mui-checked":
    {
      color: "var(--navy)",
    },
});

export const GridContainer = styled("div")({
  gridRowGap: 2,
  display: "grid",
  gridColumnGap: 12,
  paddingBottom: 16,
  alignItems: "flex-start",
  gridTemplateColumns: "30px 1fr",
  "& .MuiRadio-root, & .MuiCheckbox-root": {
    padding: 0,
  },
});

const StyledDialogContent = styled(DialogContent)({
  "&.MuiDialogContent-root": {
    padding: 0,
  },
});

const DialogTitle = styled("div")({
  backgroundColor: "var(--navy)",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "6px 16px",
  fontWeight: 500,
  display: "flex",
  color: "white",
  fontSize: 22,
});

const StyledLink = styled(Link)({
  color: "blue",
  textDecoration: "underline",
});

export default SelectPickUpAddress;
