// Customizable Area Start
import React from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import CommoncardController, { Props } from "./CommonCardController";
export class CommonCard extends CommoncardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  isApproved(isApproved: string, slug: any) {
    if (isApproved != "pending") {
      this.addToCartFromList(slug);
    } else {
      this.props.openApproved(true);
    }
  }
  render() {
    const {
      product,
      description,
      price,
      deal,
      bestSeller,
      resultDeal,
      slug,
      image,
      accountIsApproved,
      itemData,
    } = this.props;
    return (
      <div className="commonCarousel">
        <Link to={"/product/" + slug}>
          <img className="commonImages" src={image} alt={product} />
        </Link>
        <div className="contentBody">
          {product ? (
            <Link to={"/product/" + slug}>
              <h6 className="commonName">{product}</h6>
            </Link>
          ) : (
            ""
          )}
          {/* {description ? <div className="commonDesc" dangerouslySetInnerHTML={{__html: description}}></div> : '' } */}
          {price ? (
            <div className="flex">
              <span className="price">AED {price}</span>
              {itemData?.attributes?.account_id?.toString() !== localStorage.getItem('userId') ? <>{!itemData.is_in_cart ? (
                <button
                  className="filled-btn"
                  onClick={() => {
                    this.isApproved(accountIsApproved, slug);
                  }}
                >
                  Add to Cart
                </button>
              ) : (
                <button className="filled-btn">Added</button>
              )}</> : ''}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="share-icons">
          <button className="icons">
            <img className="wishlist" src="/frontend/wish.png" />
          </button>
          <button className="icons">
            <img className="share" src="/frontend/share.png" />
          </button>
          <button className="icons">
            <img className="view" src="/frontend/view.png" />
          </button>
        </div>
        {deal ? <div className="deal banner">Deal of the day</div> : ""}
        {bestSeller ? <div className="bestSeller banner">Best Seller</div> : ""}
        {resultDeal ? (
          <div className="resultDeal banner">Deal of the day</div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({ reduxState: state });
const mapDispatchToProps = (dispatch: any) => {
  return {
    reduxDispatch: { dispatch },
  };
};

// @ts-ignore
const ReduxWrapper: any = connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(CommonCard);
// @ts-ignore
export default ReduxWrapper;
// Customizable Area End
