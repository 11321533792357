// Customizable Area Start
import React from "react";
import { Grid, styled, Box, Typography, Radio } from "@material-ui/core";
import { iconFilter, iconDownload } from "../assets";

import ReportWrapperController from "./ReportWrapperController";
import MonthSelector from "./MonthSelector";

const configJs = require("./config");
const Strings = configJs.Strings;

class ReportWrapper extends ReportWrapperController {
  render() {
    const { toggleFilter, currentFilter } = this.state;
    const {
      mainTitle,
      downloadLink,
      currentMonth,
      onChangeMonth,
      visibleMonthSelector,
    } = this.props;
    return (
      <Container>
        {toggleFilter && (
          <OverlayContainer
            data-test-id="overlay-container"
            onClick={() => this.setState({ toggleFilter: false })}
          />
        )}
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Heading>{mainTitle}</Heading>
          </Grid>
          <Grid item xs="auto">
            {visibleMonthSelector && (
              <MonthSelector
                currentMonth={currentMonth || ""}
                // @ts-ignore
                onNext={() => onChangeMonth(true)}
                // @ts-ignore
                onPrevious={() => onChangeMonth(false)}
              />
            )}
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <div
                  style={{
                    position: "relative",
                    zIndex: toggleFilter ? 4 : "unset",
                  }}
                >
                  <StyledButton
                    data-test-id="btn-filter"
                    onClick={() =>
                      this.setState({ toggleFilter: !toggleFilter })
                    }
                  >
                    <img src={iconFilter} width={30} />
                  </StyledButton>
                  <DropDownMenu
                    style={{
                      visibility: toggleFilter ? "visible" : "hidden",
                    }}
                  >
                    {["Daily", "Weekly", "Monthly", "Quarterly", "Yearly"].map(
                      (label: string) => {
                        return (
                          <div
                            data-test-id="btn-filter-radio"
                            key={"month-list-item-" + label}
                            onClick={() => this.onChangeFilter(label)}
                          >
                            <Radio checked={label === currentFilter} />
                            <div>{label}</div>
                          </div>
                        );
                      }
                    )}
                  </DropDownMenu>
                </div>
              </Grid>
              <Grid item>
                <StyledButton
                  data-test-id="btn-download"
                  onClick={() => window.open(downloadLink)}
                >
                  <img src={iconDownload} width={30} />
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: 8 }} />
          </Grid>
          <Grid item xs={12}>
            {this.props.children}
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const OverlayContainer = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 3,
  backgroundColor: "rgba(0,0,0,0.3)",
});

const DropDownMenu = styled("div")({
  top: 42,
  right: 0,
  zIndex: 3,
  width: 140,
  borderRadius: 4,
  padding: "4px 8px",
  position: "absolute",
  backgroundColor: "white",
  border: "1px solid var(--navy)",
  "& > div": {
    gap: 8,
    fontSize: 14,
    display: "flex",
    fontWeight: 500,
    cursor: "pointer",
    padding: "8px 0px",
    color: "var(--navy)",
    alignItems: "center",
    borderBottom: "2px solid lightgray",
    "& > .MuiRadio-root": {
      padding: 0,
    },
  },
  "& > div:last-child": {
    borderBottom: "unset",
  },
  "& .MuiRadio-colorSecondary.Mui-checked": {
    color: "var(--navy)",
  },
});

const ReportTitle = styled(Typography)({
  fontSize: 14,
  fontWeight: 500,
  color: "var(--navy)",
});

const StyledButton = styled(Box)({
  border: "1px solid #ccc",
  cursor: "pointer",
  height: "100%",
  padding: 0,
  "&:hover": {
    borderColor: "var(--navy)",
  },
});

const Heading = styled(Typography)({
  fontSize: 20,
  fontWeight: 600,
  color: "var(--navy)",
});

const Container = styled(Box)({
  border: "1px solid rgba(0,0,0,0.3)",
  position: "relative",
  height: "100%",
  padding: 8,
});

export default ReportWrapper;

// Customizable Area End
