// Customizable Area Start
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Box, styled } from "@material-ui/core";
import FrontHeaderController from "./FrontHeaderController";
import NotificationDropDown from "./NotificationDropDown";
export class FrontHeader extends FrontHeaderController {
  // used only for code coverage
  testReduxMethods() {
    mapStateToProps({});
    const dispatch = () => null;
    let { reduxDispatch } = mapDispatchToProps(dispatch);
    reduxDispatch.dispatch();
  }

  render() {
    let iconProfile = "/frontend/user.png";
    let userProfileImage = iconProfile;
    let profile = this.state.profileData;
    if (profile) {
      // @ts-ignore
      const { profile_photo } = profile;
      userProfileImage = profile_photo ?? iconProfile;
    }
    const {
      searchText,
      homeCategoryList,
      notificationList,
      notificationCount,
    } = this.state;

    const cartCount = this.props.reduxState?.cart?.count || 0;

    return (
      <>
        <header className="headerContainer fixed-top" id="top">
          <div className="frontHeader">
            <Link to="/home">
              <img alt="logo" className="logo" src="/frontend/logo.png" />
            </Link>
            <div className="right-container">
              <div className="icon-box">
                <div className="dropdown">
                  <button
                    className="bg-transparent border-0"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img className="icons" alt="icons" src={userProfileImage} />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link className="dropdown-item" to={"/vendor/menu"}>
                        My Account
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to={"/orders"}>
                        My Orders
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="icon-box">
                <MenuWrapper>
                  <img
                    className="icons"
                    alt="icons"
                    src="/frontend/notification.png"
                  />
                  <NotificationDropDown list={notificationList} />
                </MenuWrapper>
                {notificationCount > 0 && (
                  <span className="count">{notificationCount}</span>
                )}
              </div>
              <div className="icon-box">
                <img
                  className="icons"
                  alt="icons"
                  src="/frontend/wishlist.png"
                />
              </div>
              <div className="icon-box">
                <Link to={"/cart"}>
                  <img className="icons" alt="icons" src="/frontend/cart.png" />
                  <span className="count">{cartCount}</span>
                </Link>
              </div>
            </div>
          </div>
          <nav className="menu">
            <div className="d-flex">
              <div className="categoryOption">
                <h6 className="category">Shop By Category</h6>
                <div className="submenu">
                  {homeCategoryList?.length > 0 &&
                    homeCategoryList?.map((result: any, i: number) => (
                      <div
                        className="submenu-item"
                        key={"homeCategory-item" + result.id + "-" + i}
                      >
                        <div
                          onClick={() => {
                            this.navToCategory(result?.id);
                          }}
                        >
                          <h6>{result?.attributes?.name}</h6>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="main-menu">
                <Link to="/landing-page" className="menu-item">
                  Home
                </Link>
                <Link to="/brands" className="menu-item">
                  Brands
                </Link>
                <Link to="/offers" className="menu-item">
                  Offers
                </Link>
              </div>
            </div>

            <div className="searchBox">
              <input
                type="text"
                className="search"
                value={searchText}
                placeholder="Search..."
                onChange={(e) => this.handleSearchChange(e)}
                onKeyDown={(e) => e.key === "Enter" && this.onClickSearchAll()}
              />
              <button
                className="searchBtn"
                onClick={() => this.onClickSearchAll()}
              >
                <img src="/frontend/search.png" alt="search" />
              </button>
            </div>
          </nav>
        </header>
        <div style={{ height: "154px" }}></div>
        {this.props.reduxState?.dialogues?.isApproved && (
          <div className="modalBox">
            <div className="modalContent">
              <button
                type="button"
                className="btnClose"
                onClick={() => this.closeApprovedModal()}
              >
                &times;
              </button>
              <div className="modalBody">
                <div className="message">
                  <h5 className="text-center mb-4">
                    To place an order, you are required to
                    <br />
                    register and verify your business
                  </h5>
                  <div className="buttonBox">
                    <button
                      className="buttons filled"
                      type="button"
                      onClick={() => this.closeApprovedModal()}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const MenuWrapper: any = styled(Box)({
  position: "relative",
  "&:hover .vendor-header-menu-dropdown": {
    display: "block !important",
  },
});

const mapStateToProps = (state: any) => ({ reduxState: state });
const mapDispatchToProps = (dispatch: any) => {
  return {
    reduxDispatch: { dispatch },
  };
};

// @ts-ignore
const routerWrapper: any = withRouter(FrontHeader);
const reduxWrapper: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(routerWrapper);

const FrontHeaderWithWrappers: any = reduxWrapper;

export default FrontHeaderWithWrappers;

// Customizable Area End
