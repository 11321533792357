// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import firebase from "firebase";
import ApiUrls from "./config";
import GetApiMessage from "../../../blocks/utilities/src/GetApiMessage";
import ApiResponseMiddleware from "../../vendor/src/ApiResponseMiddleware";

interface Props {
  profile?: any;
  isProtected: boolean;
  isRouteChange?: boolean;
  history: {
    push: Function;
  };
  reduxState?: {
    cart: Object | any;
    actions: Object | any;
    profile: Object | any;
    dialogues: { isApproved: boolean };
  };
  reduxDispatch?: {
    dispatch: Function;
  };
}
interface S {
  notificationListMessageId: string;
  categoriesMessageId: string;
  homeCategoryList: Array<Object>;
  searchText: string;
  cartMessageId: string;
  cartTotal: number | string;
  notificationList: Array<Object>;
  notificationCount: number;
  profileMessageId: string;
  profileData: Object;
}
interface SS {}

class FrontHeaderController extends BlockComponent<Props, S, SS> {
  state = {
    categoriesMessageId: "",
    homeCategoryList: [],
    searchText: "",
    cartMessageId: "",
    cartTotal: 0,
    notificationListMessageId: "",
    notificationList: [],
    notificationCount: 0,
    profileMessageId: "",
    profileData: {},
  };

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
    ]);
  }

  setupLiveNotificationStatus() {
    if (firebase.apps.length === 0) return;

    let database = firebase.database();
    database.goOnline();

    let notifications = database.ref("notifications");
    notifications.on("value", (snapshot) => {
      let data = snapshot.val();
      let userId = localStorage.getItem("userId") || "";
      let { count } = data[userId] || { count: 0 };

      if (this.state.notificationCount !== count) {
        this.setState({ notificationCount: count });
        this.getNotifications();
      }
    });
  }

  getUserData() {
    const requestMessage = GetApiMessage({
      method: "get",
      endpoint: ApiUrls.viewProfile,
    });

    this.setState({ profileMessageId: requestMessage.messageId });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  componentDidMount(): any {
    this.setupLiveNotificationStatus();
    this.getNotifications();
    this.getCategories();
    this.getUserData();
    this.getCart();

    const params = new URLSearchParams(window.location.search);
    const slug = params.get("s");
    if (slug) {
      const getSearchText = decodeURI(slug);
      this.setState({ searchText: getSearchText });
    }
  }

  componentDidUpdate(prevProps: Props): void {
    let currentIsRouteChange = this.props?.isRouteChange;
    let previousIsRouteChange = prevProps?.isRouteChange;
    const isRouteChange = currentIsRouteChange != previousIsRouteChange;

    // cart refetch
    let currentIsCartRefetch = this.props.reduxState?.cart?.refetch;
    let previousIsCartRefetch = prevProps.reduxState?.cart?.refetch;
    const isRefetchCart = currentIsCartRefetch != previousIsCartRefetch;

    // user refetch
    let currentIsProfileRefetch = this.props.reduxState?.profile?.refetch;
    let previousIsProfileRefetch = prevProps.reduxState?.profile?.refetch;
    let currentIsProtected = this.props.isProtected;
    let previousIsProtected = prevProps.isProtected;
    const isRefetchUser =
      currentIsProtected != previousIsProtected ||
      currentIsProfileRefetch != previousIsProfileRefetch;

    if (isRefetchCart) this.getCart();

    if (isRefetchUser) {
      this.getUserData();
      this.getCart();
    }

    if (isRouteChange) this.setState({ searchText: "" });
  }

  async receive(from: string, message: Message) {
    const { isProtected } = this.props;

    if (isProtected) ApiResponseMiddleware(message, null, this.props.history);

    const emptyFunc = () => null;
    emptyFunc();
    const dispatch: any = this.props.reduxDispatch?.dispatch || emptyFunc;
    const actions: any = this.props.reduxState?.actions || {};

    const {
      cartMessageId,
      profileMessageId,
      categoriesMessageId,
      notificationListMessageId,
    } = this.state;

    let requestID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (successResponse) {
      switch (requestID) {
        case categoriesMessageId:
          this.setState({
            homeCategoryList: successResponse.data ?? [],
          });
          break;
        case cartMessageId:
          if (successResponse.data?.length > 0) {
            let cartCount =
              successResponse?.data[0]?.attributes.order_items_count;

            dispatch({ type: actions?.cart?.SAVE_COUNT, payload: cartCount });
          } else dispatch({ type: actions?.cart?.RESET });

          break;
        case notificationListMessageId:
          this.setState({
            notificationList: successResponse.data || [],
          });
          break;
        case profileMessageId:
          this.setState({
            profileData: successResponse?.data?.attributes,
          });
          this.onSaveProfileInSession(successResponse?.data?.id);
          if (successResponse.data) {
            dispatch({
              type: actions?.profile?.SAVE,
              payload: successResponse.data || null,
            });
          }

          break;
      }
    } else {
      if (requestID === cartMessageId) {
        dispatch({ type: actions?.cart?.RESET });
      }
    }
  }

  onSaveProfileInSession(userId: any) {
    let requestMessage = new Message(getName(MessageEnum.SessionSaveMessage));
    requestMessage.addData("userId", userId);
    localStorage.setItem("userId", userId);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCategories() {
    const requestMessage = GetApiMessage({
      method: "get",
      endpoint: ApiUrls.categoryApiURL,
    });
    this.setState({ categoriesMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCart() {
    const requestMessage = GetApiMessage({
      method: "get",
      endpoint: ApiUrls.cartAPIURL,
    });
    this.setState({ cartMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSearchChange = (e: any) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  navToCategory(category: any) {
    this.props?.history?.push("/d/category/" + encodeURI(category));
  }

  onClickSearchAll() {
    const { searchText } = this.state;
    if (searchText)
      this.props?.history?.push("/d/search?s=" + encodeURI(searchText));
    else this.props?.history?.push("/home");
  }

  getNotifications() {
    let requestMessage = GetApiMessage({
      endpoint: ApiUrls.notificationsList + "?per_page=15",
    });

    this.setState({ notificationListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  closeApprovedModal() {
    const data = {
      type: this.props.reduxState?.actions?.dialogues?.accountApproved,
      payload: false,
    };
    this.props.reduxDispatch?.dispatch(data);
  }
}

export default FrontHeaderController;
// Customizable Area End
