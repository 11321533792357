// Customizable Area Start
import React  from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import Arrows from './Arrows.web';
import { BlockComponent } from "../../../framework/src/BlockComponent";
interface Props {
  items: any,
  type: string
  
}
interface State { }
interface SS { }

class ShopCategory extends BlockComponent<Props, State, SS> {

  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 6
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 6
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
  };
  const {items, type } = this.props;
  const slugUrl = (type === 'Brands') ? '/d/brand/' : '/d/category/';
    return (
      <div className='shopCategory'>
      <h6 className='heading'>Shop By {type}</h6>
      <Carousel 
          responsive={responsive} 
          showDots={false} 
          autoPlaySpeed={1000} 
          infinite
          arrows={false}
          customButtonGroup={<Arrows next={''} previous={''} />}
          renderButtonGroupOutside
      >
        {items?.length > 0 && items?.map((result:any, i:number) =>
          (
            <Link to={slugUrl + result?.id}>
              <div className='categoryCarousel' key={'categoryImage' + i}>
              <img className='categoryImages' style={{height: '225px'}} src={result?.attributes?.images ? result?.attributes?.images?.url : '/frontend/c1.png'} alt={result?.attributes?.name}/>
              <div className='contentBody'>
                <h6 className='categoryName'>{result?.attributes?.name}</h6>
                <p className='categoryDesc'>{result?.attributes?.description}</p>
              </div>
            </div>
            </Link>
          )
        )}
      </Carousel>
    </div>
    )

  }
}


export default ShopCategory;
// Customizable Area End
